<template>
    <div>
        <button
            v-if="size === 'small'"
            :disabled="disabled"
            class="relative h-10 w-24 pb-[4px] disabled:opacity-40"
            @click="$emit('customClick')"
            @touchstart="!disabled ? (isButtonPress = true) : ''"
            @touchend="isButtonPress = false"
            @touchcancel="isButtonPress = false"
            @mousedown="!disabled ? (isButtonPress = true) : ''"
            @mouseup="isButtonPress = false"
            @mouseleave="isButtonPress = false"
        >
            <img
                v-if="isButtonPress"
                class="pointer-events-none absolute bottom-0 left-0 z-0"
                :src="preloadAssetsStore.preloadedSmallActiveButtomImage"
                alt=""
                rel="preload"
            />
            <img
                v-else
                class="pointer-events-none absolute bottom-0 left-0 z-0"
                :src="preloadAssetsStore.preloadedSmallIdleButtomImage"
                alt=""
                rel="preload"
            />
            <div class="relative z-10 text-center text-base font-extrabold [text-shadow:_0px_2px_0px_#BC4F01]">
                <slot />
            </div>
        </button>
        <button
            v-else-if="size === 'medium'"
            :disabled="disabled"
            class="relative h-10 w-[120px] pb-[6px] disabled:opacity-40"
            @click="$emit('customClick')"
            @touchstart="!disabled ? (isButtonPress = true) : ''"
            @touchend="isButtonPress = false"
            @touchcancel="isButtonPress = false"
            @mousedown="!disabled ? (isButtonPress = true) : ''"
            @mouseup="isButtonPress = false"
            @mouseleave="isButtonPress = false"
        >
            <img
                v-if="isButtonPress"
                class="pointer-events-none absolute bottom-0 left-0 z-0"
                :src="preloadAssetsStore.preloadedMediumActiveButtomImage"
                alt=""
                rel="preload"
            />
            <img
                v-else
                class="pointer-events-none absolute bottom-0 left-0 z-0"
                :src="preloadAssetsStore.preloadedMediumIdleButtomImage"
                alt=""
                rel="preload"
            />
            <div class="relative z-10 text-center text-base font-extrabold [text-shadow:_0px_2px_0px_#BC4F01]">
                <slot />
            </div>
        </button>
        <button
            v-else-if="size === 'large'"
            :disabled="disabled"
            class="relative h-14 w-[360px] pb-[6px] disabled:opacity-40"
            @click="$emit('customClick')"
            @touchstart="!disabled ? (isButtonPress = true) : ''"
            @touchend="isButtonPress = false"
            @touchcancel="isButtonPress = false"
            @mousedown="!disabled ? (isButtonPress = true) : ''"
            @mouseup="isButtonPress = false"
            @mouseleave="isButtonPress = false"
        >
            <img
                v-if="isButtonPress"
                class="pointer-events-none absolute bottom-0 left-0 z-0"
                :src="preloadAssetsStore.preloadedLargeActiveButtomImage"
                alt=""
                rel="preload"
            />
            <img
                v-else
                class="pointer-events-none absolute bottom-0 left-0 z-0"
                :src="preloadAssetsStore.preloadedLargeIdleButtomImage"
                alt=""
                rel="preload"
            />
            <div class="relative z-10 text-center text-2xl font-bold [text-shadow:_0px_2px_0px_#BC4F01]">
                <slot />
            </div>
        </button>
        <button
            v-else-if="size === 'play'"
            :disabled="disabled"
            class="relative h-12 w-12 disabled:opacity-40"
            @click="$emit('customClick')"
            @touchstart="!disabled ? (isButtonPress = true) : ''"
            @touchend="isButtonPress = false"
            @touchcancel="isButtonPress = false"
            @mousedown="!disabled ? (isButtonPress = true) : ''"
            @mouseup="isButtonPress = false"
            @mouseleave="isButtonPress = false"
        >
            <img
                v-if="isButtonPress"
                class="pointer-events-none absolute bottom-0 left-0 z-0"
                :src="preloadAssetsStore.preloadedPlayActiveButtomImage"
                alt=""
                rel="preload"
            />
            <img
                v-else
                class="pointer-events-none absolute bottom-0 left-0 z-0"
                :src="preloadAssetsStore.preloadedPlayIdleButtomImage"
                alt=""
                rel="preload"
            />
            <div class="relative z-10 text-center text-2xl font-bold [text-shadow:_0px_2px_0px_#BC4F01]">
                <slot />
            </div>
        </button>
    </div>
</template>
<script setup lang="ts">
import {ref} from 'vue';
import {usePreloadAssetsStore} from '@/stores/preloadAssets';
const preloadAssetsStore = usePreloadAssetsStore();
const isButtonPress = ref(false);

withDefaults(
    defineProps<{
        size: 'small' | 'medium' | 'large' | 'play';
        disabled?: boolean;
    }>(),
    {
        size: 'medium',
        disabled: false,
    },
);
defineEmits(['customClick']);
</script>
