<!-- Loading.vue -->
<template>
    <div class="fixed left-0 top-0 z-[9999] h-screen w-screen bg-dark-900">
        <transition name="fade_fast">
            <div
                v-show="
                    preloadAssetsStore.preloadedLoadingOffImage !== '' &&
                        preloadAssetsStore.preloadedLoadingOnImage !== ''
                "
            >
                <video
                    v-if="loadingStore.isLoading"
                    ref="loadingVideoRef"
                    class="absolute h-full w-full object-cover"
                    muted
                    playsinline
                    :src="preloadAssetsStore.preloadedLoadingOffImage"
                />
                <img
                    v-else
                    :src="preloadAssetsStore.preloadedLoadingOnImage"
                    class="absolute h-full w-full object-cover"
                    alt=""
                />
            </div>
        </transition>

        <!-- 使用 flex 布局來實現置中 -->
        <div
            v-show="
                preloadAssetsStore.preloadedLoadingOffImage !== '' && preloadAssetsStore.preloadedLoadingOnImage !== ''
            "
            class="relative flex h-full w-full flex-col items-center"
        >
            <!-- Loading 進度條容器 -->
            <div class="mt-[36vh] flex flex-col items-center">
                <!-- 進度條外框 -->
                <div class="h-[10px] w-[196px] rounded-xl border border-primary-500 bg-dark-900 p-[1px]">
                    <div
                        class="h-full rounded-xl bg-primary-500 transition-all duration-700 ease-[cubic-bezier(0.22,1,0.36,1)]"
                        :style="`width: ${progress}%`"
                    />
                </div>
                <!-- Loading 文字 -->
                <div class="loading-text mt-[5px] text-center">
                    Loading...
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import {ref, watch, useTemplateRef} from 'vue';
import BezierEasing from 'bezier-easing';
import {useLoadingStore} from '@/stores/loading';
import {usePreloadAssetsStore} from '@/stores/preloadAssets';
const preloadAssetsStore = usePreloadAssetsStore();
const progress = ref('0');
const loadingStore = useLoadingStore();
// 定義目標點坐標
const target = 1;
// 定義總時間（毫秒）
const totalTime = 5000;
const loadingVideoRef = useTemplateRef('loadingVideoRef');
// 定義時間間隔（毫秒），這決定了計算精度
const interval = 100;
let currentTime = 0;
// eslint-disable-next-line new-cap
const easing = BezierEasing(0.22, 1, 0.36, 1);
/**
 * @description 計算進度條百分比
 */
async function calculatePercentage() {
    if (preloadAssetsStore.preloadedLoadingOffImage === '' || preloadAssetsStore.preloadedLoadingOnImage === '') {
        preloadAssetsStore.preloadedLoadingOffImage = await preloadAssetsStore.preloadVideoAsBase64(
            new URL('@/assets/imgs/loading/loading-off.webm', import.meta.url).href,
        );
        preloadAssetsStore.preloadedLoadingOnImage = await preloadAssetsStore.preloadImage(
            new URL('@/assets/imgs/loading/loading-on.png', import.meta.url).href,
        );
    }
    if (loadingVideoRef.value) {
        loadingVideoRef.value.play();
    }
    const t = currentTime / totalTime;
    const current = easing(t);
    if (loadingStore.isLoading === false) {
        progress.value = '100';
        currentTime = 0;
        return;
    } else {
        progress.value = ((current / target) * 99).toFixed(0);
    }

    if (current < target) {
        currentTime += interval;
        setTimeout(calculatePercentage, interval);
    } else {
        return;
    }
}
watch(
    () => loadingStore.isLoading,
    (val) => {
        if (val) {
            calculatePercentage();
        }
    },
    {immediate: true},
);
</script>

<style>
body,
html {
    margin: 0;
    padding: 0;
    overflow: hidden;
}

.loading-text {
    color: white;
    text-shadow:
        -1px -1px 0 #0066ff,
        1px -1px 0 #0066ff,
        -1px 1px 0 #0066ff,
        1px 1px 0 #0066ff;
}
</style>
