import {defineStore} from 'pinia';
import {ref} from 'vue';
export const useLoadingStore = defineStore('loading', () => {
    const isLoading = ref(true);

    /**
     * @description 開始載入
     */
    function startLoading() {
        isLoading.value = true;
    }

    /**
     * @description 開始載入
     */
    function stopLoading() {
        isLoading.value = false;
    }

    return {
        isLoading,
        startLoading,
        stopLoading,
    };
});
