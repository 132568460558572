<template>
    <div
        ref="gameWrapper"
        style="-webkit-overflow-scrolling: auto"
        class="scrollbar-hidden flex h-[200px] w-full snap-y flex-col items-center overflow-y-auto"
    >
        <div
            v-for="(game, index) in duplicatedGameList"
            :key="index"
            class="flex h-16 w-full items-center justify-center"
        >
            <div
                ref="gameRef"
                class="relative flex h-[88px] snap-center items-center justify-between gap-2 rounded-[32px] border-2 bg-dark-500 p-3 pr-4 transition-[width,font-size,opacity,filter,border-color] duration-100 will-change-[width,font-size,opacity,filter,border-color]"
                :class="{
                    'z-10 w-full border-dark-500': index === activeGameRefIndex,
                    'w-5/6': index === activeGameRefIndex - 1 || index === activeGameRefIndex + 1,
                    'w-4/6': index < activeGameRefIndex - 1 || index > activeGameRefIndex + 1,
                    'z-0 border-dark-500 text-sm opacity-75 brightness-75': index !== activeGameRefIndex,
                }"
            >
                <div class="flex h-full items-center gap-4">
                    <img
                        :src="game.icon_url"
                        class="h-full rounded-3xl border-2 border-primary-500"
                        alt=""
                    />
                    <span>{{ game.name }}</span>
                </div>
                <transition name="fade">
                    <PixelButton
                        v-show="index === activeGameRefIndex"
                        :disabled="!game.is_active"
                        size="small"
                        @click="handlePlayClick(game.id)"
                    >
                        PLAY
                    </PixelButton>
                </transition>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import {useTemplateRef, ref, nextTick, watch, computed} from 'vue';
import {useGameStore} from '@/stores/game';
import PixelButton from '../universal/PixelButton.vue';
import {useScroll} from '@vueuse/core';
import type {GameList} from '@/types/game';

const emit = defineEmits(['gameSelected']);

const singleGameItemHeight = 64;
const gameStore = useGameStore();
const gameWrapperRef = useTemplateRef('gameWrapper');
const allGamesHeight = computed(() => singleGameItemHeight * gameStore.gameList.length);
const duplicatedGameList = ref<GameList>([]);
const gameSelectorIsScrolling = defineModel<boolean>('gameSelectorIsScrolling', {default: false});
const {y: wrapperScrollY, isScrolling} = useScroll(gameWrapperRef);
const activeGameRefIndex = computed(() => {
    return Math.round(wrapperScrollY.value / singleGameItemHeight) + 1;
});

/**
 *
 */
function buildDuplicatedGames() {
    /**
     * @description 回傳已複製遊戲陣列
     * @param {GameList} arr 遊戲陣列
     * @param {number} times 複製次數
     * @returns {GameList} 已複製遊戲陣列
     */
    function repeatArray(arr: GameList, times: number): GameList {
        return [].concat(...Array(times).fill(arr));
    }

    const duplicateCount = Math.ceil(200 / allGamesHeight.value) + 20;
    duplicatedGameList.value = repeatArray(gameStore.gameList, duplicateCount);
    nextTick(() => {
        if (gameWrapperRef.value) {
            wrapperScrollY.value = (gameWrapperRef.value.scrollHeight - gameWrapperRef.value.clientHeight) / 2;
        }
    });
}

watch(
    wrapperScrollY,
    (val) => {
        if (gameWrapperRef.value === null) {
            return;
        }
        if (val < 10) {
            wrapperScrollY.value = wrapperScrollY.value + allGamesHeight.value * 10;
        } else if (val > gameWrapperRef.value.scrollHeight - gameWrapperRef.value.clientHeight - 10) {
            wrapperScrollY.value = wrapperScrollY.value - allGamesHeight.value * 10;
        }
    },
    {immediate: true},
);

// 監聽遊戲列表變化
watch(
    () => gameStore.gameList,
    (val) => {
        if (val.length > 0) {
            buildDuplicatedGames();
        }
    },
);

// 監聽選中遊戲的變化
watch(
    [duplicatedGameList, activeGameRefIndex],
    ([list, index]) => {
        if (list.length > 0 && index !== undefined) {
            const currentGame = list[index];
            if (currentGame) {
                window.Telegram.WebApp.HapticFeedback.impactOccurred('medium');
                emit('gameSelected', currentGame);
            }
        }
    },
    {immediate: true},
);

watch(isScrolling, (val) => {
    gameSelectorIsScrolling.value = val;
});
const handlePlayClick = (gameId: string) => {
    window.location.href = `/game/${gameId}/`;
};
</script>
