<template>
    <div class="relative z-0"> 
        <h2 class="p-4 text-2xl font-bold text-neutral-50">
            PRODUCTS
        </h2>

        <!-- Coming Soon Image -->
        <div v-if="telegramStarProducts.length == 0 && tiltCoinProducts.length == 0">
            <img :src="comingSoonImage"
                alt="Coming Soon"
                class="max-w-[80%] h-auto"
            />
        </div>


        <div v-else class="">

            <OverlayLoader v-if="productStore.overlayLoader" />

            <div v-if="telegramStarProducts.length > 0" class="flex-1 flex items-center justify-center w-full overflow-hidden px-4">
                <div class="space-y-4 w-full">
                    <div class="w-full overflow-x-auto scrollbar-hidden">
                        <div class="flex gap-4 pb-4 w-max snap-x snap-mandatory">
                            <div
                                v-for="telegramStarProduct in telegramStarProducts"
                                :key="telegramStarProduct.id"
                                class="w-[156px] shrink-0 space-y-2 rounded-[32px] border-2 border-primary-500 bg-dark-800 p-4 snap-start"
                            >

                                <button
                                    @click="buyWithTelegramStars(telegramStarProduct.id)"
                                >
                                    <div class="flex aspect-square w-full items-center justify-center rounded-[32px] p-[27px]">
                                        <img
                                            :src="productIcon"
                                            alt="{{ telegramStarProduct.name }}"
                                            class="h-16 w-16"
                                        />

                                    </div>
                                    <div class="space-y-1">
                                        <h3 class="text-base font-extrabold text-neutral-50">
                                            {{ telegramStarProduct.name.length > 9 ? telegramStarProduct.name.substring(0,9)+".." : telegramStarProduct.name }}
                                        </h3>
                                        <p class="font-['Space Grotesk'] text-xs font-normal text-neutral-100">
                                            {{ telegramStarProduct.description.length > 30 ? telegramStarProduct.description.substring(0,30)+".." : telegramStarProduct.description }}
                                        </p>
                                        <div class="text-lg font-extrabold text-highlight-500">
                                            <div class="relative flex items-center justify-center space-x-2 rounded-lg px-3 py-1">
                                                <img v-if="telegramStarProduct.price_type == 'coin'" class="w-4 h-4" src="@/assets/icons/top-nav/coin.png" alt="Coin Icon" />
                                                <img v-if="telegramStarProduct.price_type == 'tiltcoin'" class="w-4 h-4" src="@/assets/icons/top-nav/diamond.png" alt="Diamond Icon" />
                                                <img v-if="telegramStarProduct.price_type == 'star'" class="w-6 h-6" src="@/assets/imgs/star.png" alt="Star Icon" />

                                                <span class="text-lg font-extrabold text-highlight-500">{{ telegramStarProduct.price }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="tiltCoinProducts.length > 0" class="flex-1 flex items-center justify-center w-full overflow-hidden px-4">
                <div class="space-y-4 w-full">
                    <div class="w-full overflow-x-auto scrollbar-hidden">
                        <div class="flex gap-4 pb-4 w-max snap-x snap-mandatory">
                            <div
                                v-for="tiltCoinProduct in tiltCoinProducts"
                                :key="tiltCoinProduct.id"
                                class="w-[156px] shrink-0 space-y-2 rounded-[32px] border-2 border-primary-500 bg-dark-800 p-4 snap-start"
                            >
                                <button @click="buyWithTelegramStars(tiltCoinProduct.id)">
                                    <div class="flex aspect-square w-full items-center justify-center rounded-[32px] p-[27px]">
                                        <img
                                            :src="productIcon"
                                            alt="{{ tiltCoinProduct.name }}"
                                            class="h-16 w-16"
                                        />
                                    </div>
                                    <div class="space-y-1">
                                        <h3 class="text-base font-extrabold text-neutral-50">
                                            {{ tiltCoinProduct.name.length > 9 ? tiltCoinProduct.name.substring(0,9)+".." : tiltCoinProduct.name }}
                                        </h3>
                                        <p class="font-['Space Grotesk'] text-xs font-normal text-neutral-100">
                                            {{ tiltCoinProduct.description.length > 30 ? tiltCoinProduct.description.substring(0,30)+".." : tiltCoinProduct.description }}
                                        </p>
                                        <div class="text-lg font-extrabold text-highlight-500">
                                            <div class="relative flex items-center justify-center space-x-2 rounded-lg px-3 py-1">
                                                <img v-if="tiltCoinProduct.price_type == 'coin'" class="w-4 h-4" src="@/assets/icons/top-nav/coin.png" alt="Coin Icon" />
                                                <img v-if="tiltCoinProduct.price_type == 'tiltcoin'" class="w-4 h-4" src="@/assets/icons/top-nav/diamond.png" alt="Diamond Icon" />
                                                <img v-if="tiltCoinProduct.price_type == 'star'" class="w-6 h-6" src="@/assets/imgs/star.png" alt="Star Icon" />
                                                <span class="text-lg font-extrabold text-highlight-500">{{ tiltCoinProduct.price }}</span>

                                            </div>
                                        </div>
                                    </div>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>   
    </div>
    
</template>

<script setup lang="ts">
    import {useTemplateRef, ref, nextTick, watch, computed} from 'vue';
    import productIcon from '@/assets/imgs/products/Capa_3.png';
    import {useProductStore} from '@/stores/product';
    import PixelButton from '../universal/PixelButton.vue';
    import {useScroll} from '@vueuse/core';
    import type {ProductList} from '@/types/product';

    import OverlayLoader from '@/components/universal/OverlayLoader.vue';


    const comingSoonImage = new URL('@/assets/imgs/coming-soon.png', import.meta.url).href;

    const productStore = useProductStore();
    const telegramStarProducts = ref<ProductList>([]);
    const tiltCoinProducts = ref<ProductList>([]);
    

    /**
    *
    */
    function buildDuplicatedTelegramStarProducts() {
        telegramStarProducts.value = productStore.telegramStarProducts;
    }

    /**
    *
    */
    function buildDuplicatedTiltCoinProducts() {
        tiltCoinProducts.value = productStore.tiltCoinProducts;
    }



    // 監聽遊戲列表變化
    watch(
        () => productStore.telegramStarProducts,
        (val) => {
            if (val.length > 0) {
                buildDuplicatedTelegramStarProducts();
            }
        },
    );

    // 監聽遊戲列表變化
    watch(
        () => productStore.tiltCoinProducts,
        (val) => {
            if (val.length > 0) {
                buildDuplicatedTiltCoinProducts();
            }
        },
    );

    const buyWithTelegramStars = (productId) => {
        if (productId) {
            productStore.verifyInvoice(productId);
        }
    };

</script>