import axios from 'axios';

const meRequest = axios.create({
    baseURL: '/api/me',
});

interface BaseConfig {
    headers: {
        Authorization: string;
    };
}

export const getUserMe = (config: BaseConfig) => meRequest.get('', config);


const recordLoginRequest = axios.create({
    baseURL: '/api/record-login',
});


export const getRecordLogin = (config: BaseConfig) => recordLoginRequest.get('', config);
