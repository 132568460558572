
<template>
    
    <div id="success-toast" 
        class="fixed top-10 right-5 bg-[#1a1a2e] text-[#00d4ff] text-sm px-6 py-3 rounded-lg shadow-lg border border-[#00d4ff] transition-opacity duration-300 z-[99]">
        Successfully!
    </div>
</template>

<style scoped>
    #success-toast {
        font-family: 'Orbitron', sans-serif;
        text-transform: uppercase;
        box-shadow: 0 0 10px rgba(0, 212, 255, 0.8);
    }
</style>
