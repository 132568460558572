<template>

    <div v-if="gameRanks.length == 0" class="flex-1 flex items-center justify-center">
        <img :src="comingSoonImage"
            alt="Coming Soon"
            class="max-w-[80%] h-auto"
        />
    </div>

    <div
        v-else
        ref="gameWrapper"
        class=" scrollbar-hidden mb-6 w-full overflow-x-auto pt-4"
    >
        <div class="">
            <div
                v-for="game in gameRanks"
                class="pl-4 pr-4 pt-2"
            >
                <div class="w-full border-2 border-blue-500 rounded-[32px] text-white p-4">
                    <div class="flex items-center space-x-3">
                        <div class="size-10  self-start overflow-hidden rounded-2xl border-2 border-primary-500">
                            <img :src="game.game.icon_url"
                                alt="game.game.name"
                                class="h-full w-full object-cover"
                            />
                        </div>
                        <h2 class="text-lg font-bold">{{ game.game.name }}</h2>
                    </div>
                    <div class="flex justify-between mt-2 text-sm">
                        <div>
                            <p class="text-gray-500">SCORE</p>
                            <p class="font-semibold">{{ new Intl.NumberFormat().format(game.total_points) }}</p>
                        </div>
                        <div>
                            <p class="text-gray-500">RANKS</p>
                            <p class="font-semibold">{{ game.rank }}</p>
                        </div>
                        <div>
                            <p class="text-gray-500">TIME</p>
                            <p class="font-semibold">{{  new Date(game.updated_at).toLocaleDateString('en-CA').replace(/-/g, '/') }}</p>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>

    
</template>

<script setup lang="ts">
    import {useUserStore} from '@/stores/user';
    import {ref} from 'vue';
    import type {GameRankList} from '@/types/profile';

    const userStore = useUserStore();
    const gameRanks = ref<GameRankList>([]);
    const comingSoonImage = new URL('@/assets/imgs/coming-soon.png', import.meta.url).href;
    gameRanks.value = userStore.gameRanks;    

</script>