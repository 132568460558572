<template>
    <div class="relative px-4">
        <!-- Banner Container -->
        <div
            class="banner-shadow relative aspect-[3/1] w-full overflow-hidden rounded-[32px] border-2 border-primary-500"
        >
            <!-- Banner Images Container -->
            <div
                class="flex h-full transition-transform duration-500"
                :style="{transform: `translateX(-${currentBannerIndex * 100}%)`}"
            >
                <img
                    v-for="(banner, index) in banners"
                    :key="index"
                    :src="banner.image"
                    :alt="banner.title"
                    class="h-full w-full shrink-0 object-cover"
                />
            </div>
        </div>

        <!-- Navigation Dots -->
        <div class="absolute -bottom-4 left-1/2 flex gap-2 -translate-x-1/2 transform">
            <div
                v-for="(banner, index) in banners"
                class="h-[6px] w-[6px] rounded-full transition-colors"
                :key="index"
                :class="index === currentBannerIndex ? 'bg-primary-500' : 'bg-neutral-50/[.3]'"
                @click="goToSlide(index)"
            >
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
    import {watch, onMounted, onUnmounted, ref} from 'vue';
    import {useProductStore} from '@/stores/product';
 
    import type {BannerList} from '@/types/product';

    import {useUserStore} from '@/stores/user';
    
    const productStore = useProductStore();
    const banners = ref<BannerList>([]);
    const currentBannerIndex = ref(0);

    let autoPlayInterval: number | null = null;
   

    // Function to start auto-play
    const startAutoPlay = () => {
        stopAutoPlay(); // Ensure no duplicate intervals
        autoPlayInterval = window.setInterval(() => {
            currentBannerIndex.value = (currentBannerIndex.value + 1) % banners.value.length;
        }, 5000);
    };

    // Function to stop auto-play
    const stopAutoPlay = () => {
        if (autoPlayInterval) {
            clearInterval(autoPlayInterval);
            autoPlayInterval = null;
        }
    };

    // Function to manually navigate banners
    const goToSlide = (index: number) => {
        currentBannerIndex.value = index;
        startAutoPlay(); // Restart autoplay on manual change
    };

    // Fetch banners and start auto-play
    onMounted(async () => {
        banners.value = productStore.bannerList;
        startAutoPlay();
        await useUserStore().fetchUserDetails();
    });

    /**
    *
    */
    function buildDuplicatedBanner() {
        banners.value = productStore.bannerList;
        
        // Banner images preloading and management
        
       
        // Banner navigation functions
        const nextBanner = () => {
            currentBannerIndex.value = (currentBannerIndex.value + 1) % banners.value.length;
        };
        
        const userStore = useUserStore();

        // Lifecycle hooks
        onMounted(async () => {
            startAutoPlay();
            await userStore.fetchUserDetails();
        });

    }

   

    // 監聽遊戲列表變化
    watch(
        () => productStore.bannerList,
        (val) => {
            if (val.length > 0) {
                buildDuplicatedBanner();
                currentBannerIndex.value = 0; 
                startAutoPlay();
            }
        },
    );

    // Cleanup on unmount
    onUnmounted(() => stopAutoPlay());

</script>