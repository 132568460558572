import {z} from 'zod';

const getProductListSchema = z.object({
    product: z.array(
        z.object({
            id:  z.number(),
            name: z.string(),
            description: z.string(),
            price: z.number(),
            price_type: z.string(),
        }),
    ),
});

export {getProductListSchema};


const getBannerListSchema = z.object({
    images: z.array(
        z.object({
            image: z.string(),
            title: z.string(),
        }),
    ),
});

export {getBannerListSchema};
