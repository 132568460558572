import {defineStore} from 'pinia';
import {ref, reactive, computed} from 'vue';
import {useStorage} from '@vueuse/core';
import '@/assets/telegram-web-app.js';
import {apiPostExchangeToken, apiRecordLogin} from '@/apis';
import {exchangeTokenSchema} from '@/schemas/auth';
import {z} from 'zod';
// 預設的 initData（用於測試）
const defaultInitData = '';

export const useAuthStore = defineStore('auth', () => {

    // Telegram WebApp 實例
    const app = ref(window.Telegram.WebApp);
    // 初始化狀態
    const isInitialized = ref(false);
    // 使用 useStorage 持久化存儲 JWT Token
    const jwtToken = useStorage('jwtToken', '');
    // 登錄數據
    const loginInitData = ref(defaultInitData || app.value.initData);

    const getRequestConfig = computed(() => ({
        headers: {
            Authorization: `Bearer ${jwtToken.value}`,
        },
    }));
    const defaultTgUserInfo = {
        access_token: '',
        message: '',
        user: {
            id: '',
            name: '',
            email: '',
            email_verified_at: '',
            telegram_id: 0,
            created_at: '',
            updated_at: '',
            language_code: '',
            photo_url: '',
            last_country_code: '',
        },
        token_type: '',
        expires_in: 0,
    };
    const tgUserInfo = reactive<z.infer<typeof exchangeTokenSchema>>({...defaultTgUserInfo});

    /**
     * @description 初始化
     */
    async function initialize() {
        try {
            // 清空之前的 localStorage（如果需要）
            localStorage.clear();
            // 交換 Token
            await exchangeToken();
            isInitialized.value = true;

            setTimeout(async () => {
                await apiRecordLogin(useAuthStore().getRequestConfig);
            }, 30000);

        } catch (error) {
            console.error('Initialization failed:', error);
        }
    }
    /**
     * @description 取得token
     */
    async function exchangeToken() {
        try {
            const exchangeTokenPayload = {
                initData: loginInitData.value,
            };
            const exchangeTokenResponse = await apiPostExchangeToken(exchangeTokenPayload);
            const exchangeTokenData = exchangeTokenSchema.parse(exchangeTokenResponse.data);
            jwtToken.value = exchangeTokenData.access_token;
            Object.assign(tgUserInfo.user, exchangeTokenData.user);
        } catch (error) {
            // 捕獲並處理異常
            console.error(error);
        }
    }

    // 登出方法
    /**
     *
     */
    function logout() {
        jwtToken.value = '';
        Object.assign(tgUserInfo, {...defaultTgUserInfo});
        isInitialized.value = false;
    }

    return {
        // 狀態
        jwtToken,
        isInitialized,
        loginInitData,
        tgUserInfo,
        getRequestConfig,
        // 方法
        initialize,
        exchangeToken,
        logout,
    };
});
