import {createApp} from 'vue';
import {createPinia} from 'pinia';
import App from '@/App.vue';
import router from '@/router';
import '@/assets/telegram-web-app.js';
import '@fontsource/tektur';
import * as Sentry from "@sentry/vue";
import { browserTracingIntegration } from "@sentry/browser";
import { wasmIntegration } from '@sentry/wasm';
const app = createApp(App);
const pinia = createPinia();

// 攔截 Unity 的 console.error 和 printErr
const originalConsoleError = console.error;
console.error = function(...args) {
    // 檢查是否是 Unity/WebAssembly 相關錯誤
    const errorMessage = args.join(' ');
    if (errorMessage.includes('WebAssembly') || 
        errorMessage.includes('wasm') || 
        errorMessage.includes('Unity')) {
        
        Sentry.captureMessage('Unity WebGL Error', {
            level: 'error',
            extra: {
                originalError: errorMessage,
                timestamp: new Date().toISOString(),
                memory: {
                    deviceMemory: navigator.deviceMemory || 'unknown',
                    // 如果可用，添加更多內存信息
                    jsHeapSizeLimit: performance?.memory?.jsHeapSizeLimit,
                    totalJSHeapSize: performance?.memory?.totalJSHeapSize,
                    usedJSHeapSize: performance?.memory?.usedJSHeapSize
                }
            }
        });
    }
    originalConsoleError.apply(console, args);
};

// 監聽 Unity loader 的 printErr
window.addEventListener('load', () => {
    if (window.Module) {
        const originalPrintErr = window.Module.printErr;
        window.Module.printErr = function(text: string) {
            if (text.includes('WebAssembly') || 
                text.includes('wasm') || 
                text.includes('Unity')) {
                
                Sentry.captureMessage('Unity Loader Error', {
                    level: 'error',
                    extra: {
                        originalError: text,
                        timestamp: new Date().toISOString(),
                        source: 'Module.printErr'
                    }
                });
            }
            if (originalPrintErr) {
                originalPrintErr.apply(this, [text]);
            }
        };
    }
});

// 初始化 Sentry
Sentry.init({
    app,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [
        browserTracingIntegration({
            tracingOrigins: ["localhost", "unity2.lootex.dev", "games.tiltplay.gg", /^\//],
        }),
        wasmIntegration(), // 啟用 WASM 錯誤處理
    ],
    tracesSampleRate: 1.0,
    environment: import.meta.env.VITE_APP_ENV || 'local',
    trackComponents: true,
    // 添加全局錯誤處理
    errorHandler: (error, vm, info) => {
        console.error('Vue Error:', error);
        Sentry.captureException(error, {
            extra: {
                componentName: vm?.$options?.name,
                info,
                vue: true
            }
        });
    },
    // Unity WebGL 相關配置
    beforeBreadcrumb(breadcrumb) {
        if (breadcrumb.category === 'error' && 
            (breadcrumb.message?.includes('WebAssembly') || 
                breadcrumb.message?.includes('Unity'))) {
            breadcrumb.level = 'error';
            breadcrumb.data = {
                ...breadcrumb.data,
                engine: 'Unity WebGL',
                memory: navigator.deviceMemory || 'unknown'
            };
        }
        return breadcrumb;
    },
    beforeSend(event: any) {
        if (event.exception) {
            event.user = { 
                id: router.currentRoute.value?.params?.userId 
            };
        }
        event.tags = {
            ...event.tags,
            'vue_version': '3.x',
            'environment': import.meta.env.VITE_APP_ENV || 'local'
        };
        return event;
    }
});

app.use(pinia);
app.use(router);

// 添加一個全局的測試方法
// app.config.globalProperties.$testSentry = () => {
//     try {
//         // 故意觸發一個錯誤
//         throw new Error('這是一個來自 Vue 的測試錯誤！ - ' + new Date().toISOString());
//     } catch (error) {
//         Sentry.captureException(error);
//         console.error('Sentry test error:', error);
//     }
// };

// 全局未捕獲的 Promise 錯誤
window.addEventListener('unhandledrejection', (event) => {
    Sentry.captureException(event.reason, {
        extra: {
            type: 'unhandledrejection'
        }
    });
});

// 全局未捕獲的錯誤
window.addEventListener('error', (event) => {
    // 檢查是否是 Unity 相關錯誤
    const isUnityError = event.message?.includes('Unity') || 
                        event.message?.includes('WebAssembly');
    
    Sentry.captureException(event.error, {
        extra: {
            type: 'window.error',
            isUnityError
        }
    });
});

// Unity 實例加載完成後的處理
window.unityInstance = null;
window.addEventListener('UnityLoaded', (e: any) => {
    window.unityInstance = e.detail;
    if (window.unityInstance?.Module) {
        window.unityInstance.Module.onAbort = function(what: string) {
            Sentry.captureException(new Error('Unity WebGL Abort: ' + what), {
                tags: {
                    unity: true,
                    type: 'abort'
                }
            });
        };
    }
});

// 添加到 window 類型定義
declare global {
    interface Window {
        unityInstance: any;
        Module: {
            printErr: (text: string) => void;
            [key: string]: any;
        };
    }
    interface Performance {
        memory?: {
            jsHeapSizeLimit: number;
            totalJSHeapSize: number;
            usedJSHeapSize: number;
        };
    }
}

app.mount('#app');