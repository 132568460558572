<template>
    <div class="flex h-full flex-col items-center overflow-hidden px-4">
        <!-- 遊戲主畫面 -->
        <div
            class="my-7 w-full grow overflow-hidden rounded-[56px] border-2 border-primary-500 shadow-[0_0_24px_4px] shadow-primary-500"
        >
            <div
                v-if="selectedGame"
                class="relative h-full w-full"
            >
                <!-- Banner Image -->
                <img
                    :src="selectedGame.banner_url"
                    class="h-full w-full object-cover"
                    :alt="selectedGame.name"
                />
            </div>
        </div>

        <!-- 遊戲選擇器 -->
        <div class="relative flex w-full items-center">
            <transition name="fade_scale">
                <div
                    v-show="!gameSelectorIsScrolling"
                    class="pointer-events-none absolute left-0 z-10 h-[88px] w-full"
                >
                    <div class="size-full animate-pulse rounded-[32px] border-2 border-highlight-500" />
                </div>
            </transition>

            <GameSelector
                v-model:game-selector-is-scrolling="gameSelectorIsScrolling"
                class="relative z-0"
                @game-selected="handleGameSelected"
            />
        </div>
    </div>
</template>

<script setup lang="ts">
import GameSelector from '@/components/play/GameSelector.vue';
import {useGameStore} from '@/stores/game';
import {ref} from 'vue';
import type {GameList} from '@/types/game';

const gameStore = useGameStore();
const selectedGame = ref<GameList[number] | null>(null);
const gameSelectorIsScrolling = ref(false);
const handleGameSelected = (game: GameList[number]) => {
    selectedGame.value = game;
};

/**
 * @description 獲取遊戲對應的排行榜
 */
async function getRankingListByGameId() {
    try {
        await gameStore.fetchGames();
    } catch (error) {
        console.error(error);
    }
}
getRankingListByGameId();
</script>
<style scoped>
.fade_scale-enter-active,
.fade_scale-leave-active {
    transition: opacity 0.5s cubic-bezier(0.22, 1, 0.36, 1);
}

.fade_scale-enter-from,
.fade_scale-leave-to {
    opacity: 0;
}
</style>
