<template>
    <div class="flex h-full flex-col text-white">
        <ProfileNav />
        <!-- <div class="grow overflow-y-auto bg-dark-800" /> -->
        

        <GameRank />

    </div>
</template>
<script setup lang="ts">
import ProfileNav from '../components/layouts/ProfileNav.vue';

import GameRank from '../components/profile/GameRank.vue';

import { userDetailsSchema } from '@/schemas/user';
import axios from 'axios';

const handleError = (error: unknown) => {
    console.error('API请求错误:', error);
    // 这里可以添加错误处理逻辑，例如显示错误提示
};

const comingSoonImage = new URL('@/assets/imgs/coming-soon.png', import.meta.url).href;

const fetchUser = async () => {
    try {
        const res = await axios.get('/api/me');
        const data = userDetailsSchema.parse(res.data);
        // 使用验证后的数据
    } catch (e) {
        handleError(e);
    }
}


</script>
