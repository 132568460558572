/**
 * @description 將大數縮短，並加上量詞
 * @param {number} num 大數
 * @returns {string} 輸出字串
 */
function formatLargeNumber(num: number): string {
    const absNum = Math.abs(num);
    let result = '';

    if (absNum >= 1_000_000_000) {
        result = (num / 1_000_000_000).toFixed(1).replace(/\.0$/, '') + 'b';
    } else if (absNum >= 1_000_000) {
        result = (num / 1_000_000).toFixed(1).replace(/\.0$/, '') + 'm';
    } else if (absNum >= 1_000) {
        result = (num / 1_000).toFixed(1).replace(/\.0$/, '') + 'k';
    } else {
        result = num.toString();
    }

    // 若要求最長4字元，可在此進行控制
    // 例如，若result長度 > 4，嘗試移除小數點後一位
    if (result.length > 4 && result.includes('.')) {
        // 嘗試去掉小數位
        const unit = result.slice(-1); // 最後一個字元應為 k/m/b
        const numberPart = result.slice(0, -1);
        const integerOnly = Math.floor(parseFloat(numberPart)).toString() + unit;
        if (integerOnly.length <= 4) {
            return integerOnly;
        } else {
            // 若仍然超過4字元，可能數字本身過大，需再進一步縮短，如只取前幾位再加單位。
            // 例如將 "11500" -> "11k" 就會是3字元
            // 這就需要更進階的字串控制手法。
            return integerOnly;
        }
    }

    return result;
}
export {formatLargeNumber};
