<template>
    <div class="flex h-full flex-col text-white">
        <!-- Missions Header -->
        <div class="flex items-center justify-between p-4">
            <h1 class="text-3xl font-bold text-neutral-50">
                MISSIONS
            </h1>
            <PixelButton
                size="medium"
                :class="{'pointer-events-none opacity-40': dailyLoginLock}"
                @custom-click="questStore.handleDailyCheckIn"
            >
                DAILY LOGIN
            </PixelButton>
        </div>
        <!-- Mission Cards -->
        <div v-if="questStore.questList.length > 0" class="min-h-0 grow space-y-4 overflow-y-auto px-4">
            <div
                v-for="(mission, index) in questStore.questList"
                :key="index"
                class="rounded-[32px] border-2 border-primary-500 p-4"
            >
                
                    <div class="flex items-center gap-4">
                        <div class="flex h-[59px] w-[74px] flex-col items-center gap-0 rounded-[20px] bg-primary-500 p-2">
                            <img
                                v-if="mission.reward_type == 'coin'"
                                class="size-6"
                                src="@/assets/icons/top-nav/coin.png"
                                alt=""
                            />
                            <img
                                v-if="mission.reward_type == 'tiltcoin'"
                                class="size-6"
                                src="@/assets/icons/top-nav/diamond.png"
                                alt=""
                            />
                            <div class="text-center text-sm text-white">
                                {{ mission.reward }}
                            </div>
                        </div>
                        <div class="flex-1">
                            <p
                                class="text-xs"
                                style="font-family: Space Grotesk"
                            >
                                {{ mission.description }}
                            </p>
                            <div
                                class="mt-2 h-2"
                            >
                                <ProgressBar
                                    v-if="!mission.is_completed"
                                    :max-num="5"
                                    :current-num="0"
                                    active-progress-color="bg-primary-500"
                                    active-progress-accent-color="bg-primary-700"
                                />
                            </div>
                        </div>
                        <div class="relative inline-block">
                            <PixelButton
                                v-if="mission.is_completed && !mission.is_collected"
                                size="small"
                                @click="questStore.claimReward(index, mission.id, mission.reward, mission.reward_type, 'quest')"
                                :disabled="questStore.questOverlayLoaderIndex === index"
                            >
                                
                                CLAIM
                            </PixelButton>

                             <OverlayLoader 
                                v-if="questStore.questOverlayLoaderIndex === index" 
                                class="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 rounded"
                            />
                        </div>
                    </div>
                
            </div>
        </div>
        <!-- Coming Soon Image -->
        <div v-else class="flex-1 flex items-center justify-center">
            <img :src="comingSoonImage"
                alt="Coming Soon"
                class="max-w-[80%] h-auto"
            />
        </div>
        <div class="flex justify-center gap-4 hidden">
            <PixelButton
                size="large"
                @click="handleShare"
            >
                <div class="text-xl leading-[30px]">
                    SHARE WITH FRIENDS
                </div>
            </PixelButton>
            
            <!-- Sentry 測試按鈕
            <PixelButton
                size="large"
                @click="testSentry"
                class="!bg-red-500 hover:!bg-red-600"
            >
                <div class="text-xl leading-[30px]">
                    TEST SENTRY
                </div>
            </PixelButton> -->
        </div>
        <!-- Daily Login Modal -->
        <div
            v-if="showLoginModal"
            class="fixed inset-0 flex items-center justify-center p-4"
        >
            <div class="rounded-[32px] border-2 border-primary-500 bg-neutral-900 p-6 text-center">
                <div class="mb-4 flex items-center justify-center gap-4">
                    <img
                        class="size-8"
                        src="@/assets/icons/top-nav/coin.png"
                        alt="Reward"
                    />
                </div>
                <p class="mb-6 text-lg">
                    {{ dailyLoginResult.message }}
                </p>
                <PixelButton
                    size="medium"
                    @custom-click="closeLoginModal"
                >
                    CONFIRM
                </PixelButton>
            </div>
        </div>
    </div>

    <DailyLoginModal />

    <SuccessMessage v-if="questStore.successMessage" />

    <ErrorMessage v-if="questStore.errorMessage" />

</template>
<script setup lang="ts">
import {ref, onMounted, getCurrentInstance} from 'vue';
import PixelButton from '@/components/universal/PixelButton.vue';
import OverlayLoader from '@/components/universal/OverlayLoader.vue';
import DailyLoginModal from '@/components/quest/DailyLoginModal.vue';
import ErrorMessage from '@/components/messages/ErrorMessage.vue';
import SuccessMessage from '@/components/messages/SuccessMessage.vue';
import ProgressBar from '@/components/universal/ProgressBar.vue';


import {postDailyCheckingSchema} from '@/schemas/quest';
import {apiPostDailyCheckIn} from '@/apis';
import {useAuthStore} from '@/stores/auth';
import {useUserStore} from '@/stores/user';
import {useQuestStore} from '@/stores/quest';



const comingSoonImage = new URL('@/assets/imgs/coming-soon.png', import.meta.url).href;

const authStore = useAuthStore();
const userStore = useUserStore();
const questStore = useQuestStore();
const dailyLoginLock = ref(false);
const showLoginModal = ref(false);
const dailyLoginResult = ref({
    success: false,
    message: '',
});

// 每日登入按鈕初始狀態設定
onMounted(() => {
    dailyLoginLock.value = userStore.dailyCheckIn.isCompleted;
});

const tgBotName = import.meta.env.VITE_TELEGRAM_BOT_NAME;

const handleShare = async () => {
    try {
        const app = window.Telegram.WebApp;
        const botLink = `https://t.me/share/url?url=https://t.me/${tgBotName}&text=${encodeURIComponent('Check out this awesome game! 🎮')}`;
        
        if (app) {
            // 使用 Telegram 的原生分享功能
            app.openTelegramLink(botLink);
        } else if (navigator.share) {
            // 回退到 Web Share API
            await navigator.share({
                title: 'Check out this game!',
                text: `Check out this awesome game! 🎮\nhttps://t.me/${tgBotName}`,
            });
        } else {
            console.log('No sharing method available');
        }
    } catch (error) {
        console.error('Error sharing:', error);
    }
};

/**
 * @description 每日登入
 */
async function handleDailyCheckIn() {
    

    try {
        const dailyCheckInResponse = await apiPostDailyCheckIn(authStore.getRequestConfig);
        const dailyCheckInData = postDailyCheckingSchema.parse(dailyCheckInResponse.data);

        if (dailyCheckInData.success) {
            userStore.currency.hardCurrency = dailyCheckInData.current_balance.hard_currency;
            userStore.currency.softCurrency = dailyCheckInData.current_balance.soft_currency;
            userStore.dailyCheckIn.isCompleted = true;
            dailyLoginLock.value = true;

            dailyLoginResult.value = {
                success: true,
                message: 'Daily login reward claimed!',
            };
        }
        showLoginModal.value = true;
    } catch (error) {
        console.error(error);
        dailyLoginResult.value = {
            success: false,
            message: 'Failed to claim daily reward. Please try again.',
        };
        showLoginModal.value = true;
    }
}

/**
 * @description 關閉每日登入結果視窗
 */
function closeLoginModal() {
    showLoginModal.value = false;
}

// 添加 Sentry 測試功能
const app = getCurrentInstance();
const testSentry = () => {
    app?.proxy.$testSentry();
};

async function fetchQuest() {
    try {
        await questStore.fetchQuest();
    } catch (error) {
        console.error(error);
    }
}

fetchQuest();
</script>

<style scoped>
/* Add any component-specific styles here if needed */
</style>
