<template>
    <div class="flex h-full flex-col text-white">
        <!-- Header -->
        <div class="flex items-center justify-between p-4">
            <h1 class="text-3xl font-bold text-neutral-50">
                STORE
            </h1>
        </div>

        <!-- Banner Section -->
        <BannerSelector />
        

        <!-- Coming Soon Image -->
        <div class="hidden">
            <img :src="comingSoonImage"
                alt="Coming Soon"
                class="max-w-[80%] h-auto"
            />
        </div>

        <!-- Products Section -->
        <ProductSelector />

        <InvoiceModal />

        <SuccessMessage v-if="productStore.successMessage" />

        <ErrorMessage v-if="productStore.errorMessage" />

    </div>
</template>

<script setup lang="ts">
import ProductSelector from '@/components/product/ProductSelector.vue';
import BannerSelector from '@/components/product/BannerSelector.vue';
import InvoiceModal from '@/components/product/InvoiceModal.vue';
import ErrorMessage from '@/components/messages/ErrorMessage.vue';
import SuccessMessage from '@/components/messages/SuccessMessage.vue';


import {ref, computed, onMounted, onUnmounted} from 'vue';
import {useUserStore} from '@/stores/user';

import {useProductStore} from '@/stores/product';
import type {ProductList} from '@/types/product';

const productStore = useProductStore();


/**
 * @description 獲取遊戲對應的排行榜
 */
async function getProductListItem() {
    try {
        await productStore.fetchProducts(1);
        await productStore.fetchProducts(2);
    } catch (error) {
        console.error(error);
    }
}
getProductListItem();

/**
 * @description 獲取遊戲對應的排行榜
 */
async function getBannerListItem() {
    try {
        await productStore.fetchBanners();
    } catch (error) {
        console.error(error);
    }
}
getBannerListItem();


</script>
<style scoped>
.scrollbar-hidden::-webkit-scrollbar {
    display: none;
}

.scrollbar-hidden {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.banner-shadow {
    box-shadow: 0 0 24px 4px rgba(37, 119, 251, 0.95);
}
</style>
