import {postExchangeToken} from './token';
import {getUserMe, getRecordLogin} from './me';
import {getUserFlag} from './flag';
import {getGameList} from './games';
import {getProductList, getBannerList, postSendInvoice, postVerifyInvoice} from './products';
import {getQuestList, postDailyCheckIn} from './quests';
import {getGameLeaderboardList} from './rankings';
import {postClaimReward} from './reward';

export const apiPostExchangeToken = postExchangeToken;
export const apiGetUserMe = getUserMe;
export const apiGetUserFlag = getUserFlag;
export const apiGetGameList = getGameList;
export const apiGetProductList = getProductList;
export const apiGetBannerList = getBannerList;
export const apiSendInvoice = postSendInvoice;
export const apiVerifyInvoice = postVerifyInvoice;
export const apiGetQuestList = getQuestList;
export const apiPostDailyCheckIn = postDailyCheckIn;
export const apiGetGameLeaderboardList = getGameLeaderboardList;
export const apiClaimReward = postClaimReward;
export const apiRecordLogin = getRecordLogin;

