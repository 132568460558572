import axios from 'axios';

const questRequest = axios.create({
    baseURL: '/api/quests',
});

interface BaseConfig {
    headers: {
        Authorization: string;
    };
}

export const getQuestList = (config: BaseConfig) => questRequest.get('', config);
export const postDailyCheckIn = (config: BaseConfig) => questRequest.post('daily-check-in', {}, config);
