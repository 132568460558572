import {defineStore} from 'pinia';
import {ref} from 'vue';
import {useAuthStore} from './auth';
import type {ProductList, BannerList} from '@/types/product';
import {getProductListSchema, getBannerListSchema} from '@/schemas/product';
import {apiGetProductList, apiGetBannerList, apiSendInvoice, apiVerifyInvoice, apiGetUserMe} from '@/apis';
import {userDetailsSchema} from '@/schemas/user';
import {useUserStore} from './user';


export const useProductStore = defineStore('product', () => {
    const authStore = useAuthStore();
    const userStore = useUserStore();

    const telegramStarProducts = ref<ProductList>([]);
    const tiltCoinProducts = ref<ProductList>([]);
    const bannerList = ref<BannerList>([]);

    const showInvoiceModal = ref(false);  // Reactive state
    const productId = ref<any>(null);
    const showInvoiceModalMessage = ref<string>("");

    const successMessage = ref<boolean>(false);
    const errorMessage = ref<boolean>(false);
    const paymentProcess = ref<boolean>(false);

    const overlayLoader = ref<boolean>(false);

    const appUrl = import.meta.env.VITE_APP_URL || '';

    /**
     * @description 取得遊戲列表
     */
    async function fetchProducts(type:number) {
        try {
            const getProductListResponse = await apiGetProductList(type);
            const products = getProductListSchema.parse(getProductListResponse.data.data).product;
            // Add coming soon game to the end of the list

            //const tidalRush = games.find((game) => game.name === 'Tidal Rush');

            if (type == 1) {
                telegramStarProducts.value = [
                    ...products
                ];
            } else {
                tiltCoinProducts.value = [
                    ...getProductListSchema.parse(getProductListResponse.data.data).product
                ];
            }

        } catch (error) {
            console.error('Error fetching products:', error);
            // Even on error, ensure coming soon game is present
            
        }
    }

    /**
     * @description 取得遊戲列表
     */
    async function fetchBanners() {
        try {
            const getBannerListResponse = await apiGetBannerList();
            const banners = getBannerListSchema.parse(getBannerListResponse.data.data).images;
            // Add coming soon game to the end of the list

            bannerList.value = [
                ...banners
            ];
        } catch (error) {
            console.error('Error fetching banners:', error);
            // Even on error, ensure coming soon game is present
            
        }
    }

    async function verifyInvoice(product_id:number) {
        if (paymentProcess.value) {
            //return false;
        }
        overlayLoader.value = true;
        paymentProcess.value = true;
        productId.value = product_id;

        sendInvoice();
        // try {
        //     overlayLoader.value = true;
        //     productId.value = product_id;
        //     const verifyInvoiceResponse = await apiVerifyInvoice(authStore.getRequestConfig, product_id);

        //     if (verifyInvoiceResponse.data.success) {
        //         sendInvoice();
        //     } else {
        //         showInvoiceModalMessage.value = verifyInvoiceResponse.data.message;
        //         showInvoiceModal.value = true;
        //     }

        // } catch (error) {
        //     overlayLoader.value = false;
        //     errorMessage.value = true;
        //     setTimeout(() => {
        //         errorMessage.value = false;
        //     }, 3000);
        //     console.error('Error while buying star:', error);
        //     // Even on error, ensure coming soon game is present
        // }
    }

    async function sendInvoice() {
        try {
            const sendInvoiceResponse = await apiSendInvoice(authStore.getRequestConfig, productId.value);
            closeinvoiceConfirmationModal();
            if (sendInvoiceResponse.data.success) {
                if (sendInvoiceResponse.data.data.url) {
                    window.Telegram.WebApp.openInvoice(sendInvoiceResponse.data.data.url, async (status) => {
                        paymentProcess.value = false;
                        if (status === 'paid') {
                            const getUserResponse = await apiGetUserMe(authStore.getRequestConfig);
                            const getUserData = userDetailsSchema.parse(getUserResponse.data);
                            userStore.updateEnergy(getUserData.energy);

                            successMessage.value = true;
                            setTimeout(() => {
                                successMessage.value = false;
                            }, 3000);

                            console.log("Payment successful!");
                            // Perform necessary post-payment actions, such as updating the UI or database
                        } else if (status === 'cancelled') {
                            console.log("Payment was cancelled.");
                        } else {
                            console.log("Unknown payment status:", status);
                        }
                    });
                } else {

                    const getUserResponse = await apiGetUserMe(authStore.getRequestConfig);
                    const getUserData = userDetailsSchema.parse(getUserResponse.data);
    
                    userStore.updateEnergy(getUserData.energy);
    
                    successMessage.value = true;
                    setTimeout(() => {
                        successMessage.value = false;
                    }, 3000);
                }

            } else {
                errorMessage.value = true;
                setTimeout(() => {
                    errorMessage.value = false;
                }, 3000);
            }
        } catch (error) {
            overlayLoader.value = false;
            closeinvoiceConfirmationModal();
            console.error('Error while buying star:', error);
            // Even on error, ensure coming soon game is present
            
        }
    }

    function closeinvoiceConfirmationModal() {
        showInvoiceModal.value = false;
        overlayLoader.value = false;
    }

    return {
        telegramStarProducts,
        tiltCoinProducts,
        showInvoiceModal,
        showInvoiceModalMessage,
        errorMessage,
        successMessage,
        overlayLoader,
        fetchProducts,
        bannerList,
        fetchBanners,
        verifyInvoice,
        sendInvoice,
        closeinvoiceConfirmationModal
    };
});

