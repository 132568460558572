<template>
  <div v-if="questStore.isOpen" class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
    <div class="w-96 mx-4 bg-gray-900 p-5 rounded-2xl shadow-[0px_0px_20px_5px_rgba(0,128,255,0.5)] text-white relative border-4 border-primary-500 flex flex-col max-h-[90vh]">
      
      <!-- Close Button -->
      <button @click="questStore.closeModal" class="absolute top-2 right-2 text-gray-300 hover:text-white">
        ✖
      </button>

      <!-- Modal Header -->
      <div class="text-center font-['Space Grotesk']">
        <h2 class="text-2xl md:text-xl font-extrabold tracking-wider">DAILY LOGIN REWARD</h2>
        <p class="text-xs md:text-sm text-gray-400 mt-2">Log in daily to earn rewards!</p>
      </div>

      <!-- Scrollable Content -->
      <div class="flex-1 overflow-y-auto mt-4 max-h-[65vh] scrollbar-thin scrollbar-thumb-gray-500 scrollbar-track-gray-800 pr-2">
        <div class="grid grid-cols-3 gap-2">
          <template v-for="(reward, index) in questStore.rewardsList" :key="index">
            <div
              :class="[ 
                'p-1 rounded-[16px] text-center bg-[#2577FB66]  flex flex-col items-center justify-center relative inline-block',
                reward.is_collected ? 'border-green-500 border-2 checkbefore relative' : 'border-primary-500 border-transparent border-2',
                (index + 1) % 4 === 0 ? 'col-span-3 w-full bg-img bg-[#2577FB66]  py-2' : '',
                !reward.is_completed ? 'absolute inset-0 bg-[#15181e] opacity-75 z-10' : ''
              ]"
            >
                <button 
                    v-if="reward.is_completed && !reward.is_collected"
                    @click="questStore.claimReward(index, reward.id, reward.reward, reward.reward_type, 'dailyquest' )"
                    :disabled="questStore.dailyCheckinOverlayLoaderIndex === index"
                    class="inline-block"
                >
                    <p class="text-xs md:text-sm ">Day {{ reward.day_count }}</p>
                    <img v-if="reward.reward_type == 'Coin'" src="@/assets/icons/top-nav/coin.png" alt="coin" class="w-8 h-8 mt-1 mb-1 mx-auto my-1" />
                    <img v-if="reward.reward_type == 'TiltCoin'" src="@/assets/icons/top-nav/diamond.png" alt="coin" class="w-8 h-8 mx-auto my-1" />
                    <img v-if="reward.reward_type == 'Other'" :src="reward.reward_image" alt="other" class="w-8 h-8 mx-auto my-1" />
                    <p class="md:text-xl font-bold text-white-400">{{ reward.reward }}</p>
                </button>
                <div v-else class="relative  flex flex-col items-center justify-center  ">
                    <p class="text-xs md:text-sm ">Day {{ reward.day_count }}</p>
                    <img v-if="reward.reward_type == 'Coin'" src="@/assets/icons/top-nav/coin.png" alt="coin" class="w-8 h-8 mx-auto my-1" />
                    <img v-if="reward.reward_type == 'TiltCoin'" src="@/assets/icons/top-nav/diamond.png" alt="coin" class="w-8 h-8 mx-auto my-1" />
                    <img v-if="reward.reward_type === 'Other'" :src="reward.reward_image" alt="other" class="w-8 h-8 mx-auto my-1" />

                    <p class="md:text-md text-md font-bold text-white-400">{{ reward.reward }}</p>

                    <!-- Overlay when collected -->
                    <div v-if="reward.is_collected" class="absolute inset-0 flex items-center justify-center rounded-lg">
                      <span class="text-green-500 text-2xl font-bold checkicon">✔</span>
                    </div>
                </div>
                <OverlayLoader 
                    v-if="questStore.dailyCheckinOverlayLoaderIndex === index" 
                    class="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 rounded"
                />
            </div>
          </template>
        </div>
      </div>

    </div>
  </div>
</template>

<script setup>
    import { defineProps, defineEmits } from 'vue';
    import { useQuestStore } from '@/stores/quest';
    import OverlayLoader from '@/components/universal/OverlayLoader.vue';

    const questStore = useQuestStore();

    const props = defineProps({

        currentDay: Number
    });


</script>


<style scoped>
    /* Custom scrollbar styles */
    ::-webkit-scrollbar {
        width: 6px;
    }
    ::-webkit-scrollbar-thumb {
        background: #555;
        border-radius: 4px;
    }
    ::-webkit-scrollbar-track {
        background: #222;
    }

    .bg-img {
        background-image: url(@/assets/imgs/box-bg.png);
        background-size: 100% auto;
        background-position: 0 bottom;
        background-repeat: no-repeat;


    }
.checkicon{
      font-size: 35px;
    position: relative;
    top: -5px;
    font-family: arial;
    z-index:2;
}

.checkbefore:before{
  Content:'';
     background:#15181E;
     opacity:.75;
    z-index:1;
       position: absolute;
    top: 0;
    left:0; 
    right:0;
    width:100%;
    height:100%;
    bottom:0;
    border-radius:16px;
}
.box-shadow {
   box-shadow: 10px 10px 20px #FF6B00;
}

</style>