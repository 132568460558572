import {defineStore} from 'pinia';
import {ref} from 'vue';
import type {GameList} from '@/types/game';
import {getGameListSchema} from '@/schemas/game';
import {apiGetGameList} from '@/apis';

export const useGameStore = defineStore('game', () => {
    const gameList = ref<GameList>([]);
    const appUrl = import.meta.env.VITE_APP_URL || '';

    const comingSoonGame = {
        id: 'coming-soon',
        name: 'Coming Soon',
        game_url: '',
        banner_url: `${appUrl}/banners/coming-soon.png`,
        icon_url: `${appUrl}/icons/coming-soon.png`,
        description: 'Coming Soon',
        is_active: false,
        sort_order: 999,
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString(),
        points_to_soft_currency_rate: '0',
        energy_cost: 0,
        max_gamepoints_per_game: 0,
    };

    /**
     * @description 取得遊戲列表
     */
    async function fetchGames() {
        try {
            const getGameListResponse = await apiGetGameList();
            const games = getGameListSchema.parse(getGameListResponse.data).games;
            // Add coming soon game to the end of the list

            const tidalRush = games.find((game) => game.name === 'Tidal Rush');

            gameList.value = [
                ...games.filter((game) => game.name !== 'Tidal Rush'),
                ...(tidalRush ? [{...tidalRush, is_active: true}] : [])
            ];
        } catch (error) {
            console.error('Error fetching games:', error);
            // Even on error, ensure coming soon game is present
            gameList.value = [comingSoonGame];
        }
    }

    return {
        gameList,
        fetchGames,
    };
});
