<template>
    <div id="error-toast" 
        class="fixed top-10 right-5 bg-[#2e1a1a] text-[#ff4d4d] text-sm px-6 py-3 rounded-lg shadow-lg border border-[#ff4d4d] transition-opacity duration-300 z-[99]">
        Error! Something went wrong.
    </div>
</template>

<style scoped>
    #error-toast {
        font-family: 'Orbitron', sans-serif;
        text-transform: uppercase;
        box-shadow: 0 0 10px rgba(0, 212, 255, 0.8);
    }

    /* Specific glow for error */
    #error-toast {
        box-shadow: 0 0 10px rgba(255, 77, 77, 0.8);
    }
</style>