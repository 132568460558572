import axios from 'axios';

interface BaseConfig {
    headers: {
        Authorization: string;
    };
}

const claimRewardRequest = axios.create({
    baseURL: '/api/claim-reward',
});
export const postClaimReward = (
    config: BaseConfig,
    reward_id: string | number,
    reward_amount: number,
    reward_type: string,
    reward_from: string
) => claimRewardRequest.post(
    '',
    {
        'reward_id': reward_id,
        'reward_amount': reward_amount,
        'reward_type': reward_type,
        'reward_from': reward_from
    },
    config
);

