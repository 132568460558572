import {defineStore} from 'pinia';
import {computed, reactive, ref} from 'vue';
import {useAuthStore} from './auth';
import {apiGetUserMe} from '@/apis';
import {z} from 'zod';
import {userDetailsSchema} from '@/schemas/user';
import type {GameRankList} from '@/types/profile';

export const useUserStore = defineStore('user', () => {
    const authStore = useAuthStore();

    const gameRanks = ref<GameRankList>([]);

    // 能量狀態
    const energy = reactive<{
        current: number;
        max: number;
        nextRefill: null | string;
        lastConsumed: null | string;
    }>({
        current: 0,
        max: 0,
        nextRefill: null,
        lastConsumed: null,
    });

    // 貨幣狀態
    const currency = reactive({
        softCurrency: 0,
        hardCurrency: 0,
    });
    // 每日簽到狀態
    const dailyCheckIn = reactive({
        isCompleted: false,
        rewards: {
            softCurrency: 0,
        },
        nextReset: '',
    });

    // 在store中添加用户信息状态
    const user = reactive({
        id: '',
        name: '',
        telegramId: 0,
    });


    const userInfo = computed(() => {
        return {
            userName: authStore.tgUserInfo.user.name,
            userAvatar: authStore.tgUserInfo.user.photo_url,
            diamond: currency.hardCurrency,
            coin: currency.softCurrency,
            energy: energy.current,
            nextRefull: energy.nextRefill,
            wallet: authStore.tgUserInfo.user.id,
            countryCode: authStore.tgUserInfo.user.last_country_code,
        };
    });

    // 登入狀態
    const isLoggedIn = computed(() => !!authStore.tgUserInfo.access_token);

    /**
     * @description 從伺服器擷取使用者詳細資訊
     */
    async function fetchUserDetails() {
        try {
            const response = await apiGetUserMe(authStore.getRequestConfig);
            const data = userDetailsSchema.parse(response.data);

            const game_rank = data.game_rank;

            gameRanks.value = Array.isArray(game_rank) ? [...game_rank] : [];
            
            // 新增用户信息解析
            user.id = data.user.id;
            user.name = data.user.name;
            user.telegramId = data.user.telegram_id;

            // 确保使用正确的字段名称
            currency.hardCurrency = data.currency.hard_currency;
            currency.softCurrency = data.currency.soft_currency;

            energy.current = data.energy.current;
            energy.max = data.energy.max;
            energy.nextRefill = data.energy.next_refill;
            energy.lastConsumed = data.energy.last_consumed;
            // 更新每日簽到狀態
            if (data.daily_check_in) {
                dailyCheckIn.isCompleted = data.daily_check_in.is_completed;
                dailyCheckIn.rewards.softCurrency = data.daily_check_in.rewards.soft_currency;
                dailyCheckIn.nextReset = data.daily_check_in.next_reset;
            } else {
                dailyCheckIn.isCompleted = false;
                dailyCheckIn.rewards.softCurrency = 0;
                dailyCheckIn.nextReset = '';
            }
        } catch (error) {
            console.error('取得使用者詳細資訊時發生錯誤：', error);
        }
    }

    function updateEnergy(energy: any) {
        energy.current = energy.current;
        energy.max = energy.max;
        energy.nextRefill = energy.next_refill;
        energy.lastConsumed = energy.last_consumed;
    }


    function updateCurrency(updatedCurrency: any) {
        currency.hardCurrency = updatedCurrency.hard_currency;
        currency.softCurrency = updatedCurrency.soft_currency;
    }

    return {
        user,
        energy,
        currency,
        userInfo,
        dailyCheckIn,
        isLoggedIn,
        gameRanks,
        fetchUserDetails,
        updateEnergy,
        updateCurrency,
    };
});
