import {z} from 'zod';
export const gameRankingSchema = z.object({
    current_week: z.object({
        week_number: z.number(),
        year: z.number(),
        rankings: z.array(
            z.object({
                rank: z.number(),
                user_name: z.string(),
                total_points: z.number(),
                last_country_code: z.string(),
                photo_url: z.string().nullable().optional(),
                reward: z.number(),
                reward_type: z.string(),
                reward_detail: z.object({
                    id: z.number(),
                    rank: z.number(),
                    reward: z.number(),
                    reward_type: z.string(),
                    reward_image: z.string().nullable(),
                    reward_name: z.string().nullable(),
                }),
                is_completed: z.boolean(),
                is_collected: z.boolean(),
            }),
        ),
    }),
    previous_week: z.object({
        week_number: z.number(),
        year: z.number(),
        rankings: z.array(
            z.object({
                rank: z.number(),
                user_name: z.string(),
                total_points: z.number(),
                last_country_code: z.string(),
                photo_url: z.string().nullable().optional(),
                reward_detail: z.object({
                    id: z.number(),
                    rank: z.number(),
                    reward: z.number(),
                    reward_type: z.string(),
                    reward_image: z.string().nullable(),
                    reward_name: z.string().nullable(),
                }),
                reward: z.number(),
                reward_type: z.string(),
                is_completed: z.boolean(),
                is_collected: z.boolean(),
            }),
        ),
    }),
});
