<template>
    <div class="flex h-full flex-col">
        <div class="flex flex-col">
            <div class="flex justify-center gap-4 py-4">
                <button
                    v-for="game in gameStore.gameList"
                    :key="game.id"
                    class="transition-all duration-200"
                    :class="[
                        showingGameId === game.id ? 'opacity-100' : 'opacity-50 hover:opacity-75',
                        !game.is_active ? 'cursor-not-allowed grayscale' : 'cursor-pointer',
                    ]"
                    @click="game.is_active && (showingGameId = game.id)"
                >
                    <img
                        :src="game.icon_url"
                        class="size-16 rounded-3xl border-2 border-primary-500 object-cover"
                        alt=""
                    />
                </button>
            </div>
            <!-- Rest of the ranking display -->
            <div class="flex min-h-0 grow flex-col px-4">
                <!-- Original content -->
            </div>
        </div>
        <div class="flex min-h-0 grow flex-col px-4">
            <h1 class="mb-4 text-3xl font-bold">
                RANKS
            </h1>
            <div class="mb-7 flex w-full items-center justify-between">
                <div class="text-xs font-extrabold">
                    <div>ENDS IN</div>
                    <div class="text-highlight-500">
                        {{ countdown }}
                    </div>
                </div>
                <PixelButton
                    size="medium"
                    @click="isPrevious = !isPrevious"
                >
                    {{ isPrevious ? 'CURRENT' : 'PREVIOUS' }}
                </PixelButton>
            </div>
            <div
                class="relative mb-6 flex flex-col gap-6 rounded-[32px] bg-primary-500 p-1 pb-3 text-white shadow-[0px_0px_24px_4px_rgba(37,119,251,0.95)]"
            >
                <div class="absolute -left-2 -top-3.5">
                    <div class="relative size-[88px]">
                        <!-- <router-link :to="{name: ROUTER_NAME.FILE}"> -->
                        <img
                            :key="isPrevious ? 'prev' : 'curr'"
                            :src="showingRank?.rankings?.[0]?.photo_url || questionImg"
                            alt=""
                            class="size-[88px] rounded-[32px] border-2 border-primary-500"
                        />
                        <!-- </router-link> -->
                        <img
                            :src="`https://flagcdn.com/w40/${(showingRank.rankings?.[0]?.last_country_code || 'us').toLowerCase()}.png`"
                            alt=""
                            class="absolute bottom-0 right-0 size-6 rounded-2xl border-2 border-primary-500 object-cover"
                        />
                    </div>
                </div>
                <img
                    class="absolute -right-1.5 -top-1 size-[68px]"
                    src="@/assets/imgs/ranks/cup.png"
                    alt=""
                />
                <div class="flex h-[52px] items-center justify-center rounded-[32px] bg-black text-2xl font-bold">
                    CHAMPION
                </div>
                <div class="flex items-center justify-between px-[17.5px] text-3xl font-bold gap-2">
                    {{ (showingRank.rankings?.[0]?.user_name?.slice(0, 6) + (showingRank.rankings?.[0]?.user_name?.length > 6 ? '...' : '')) || 'No Players Yet' }}

                    <div class="flex flex-col">
                        <div v-if="showingRank.rankings?.[0]?.reward_detail?.reward_type == 'TiltCoin'" class="relative flex h-6 w-[30px] items-center justify-end rounded-lg bg-primary-500/[.3]">
                            <img
                                class="absolute left-0 size-6"
                                src="@/assets/icons/top-nav/diamond.png"
                                alt=""
                            />
                        </div>
                    

                        <div v-else-if="showingRank.rankings?.[0]?.reward_detail?.reward_type == 'Coin'" class="relative flex h-6 w-[30px] items-center justify-end rounded-lg bg-primary-500/[.3]">
                            <img
                                class="absolute left-0 size-6"
                                src="@/assets/icons/top-nav/coin.png"
                                alt=""
                            />
                        </div>

                    </div>
                    <div class="flex flex-col">

                        <div class="font-['Space Grotesk'] text-xs font-medium">
                            REWARD
                        </div>
                        <div class="text-xl font-bold">
                            {{ showingRank.rankings?.[0]?.reward?.toLocaleString('en-US') || '0' }}
                        </div>
                    </div>
                    <div class="flex flex-col">
                        <div class="font-['Space Grotesk'] text-xs font-medium">
                            SCORE
                        </div>
                        <div class="text-xl font-bold">
                            {{ showingRank.rankings?.[0]?.total_points?.toLocaleString('en-US') || '0' }}
                        </div>
                    </div>
                </div>
            </div>
            <!-- Table Header -->
                <div class="flex items-center  gap-2 pl-4 pr-4 font-['Space Grotesk']">
                    <div class="flex items-center gap-1 w-[64px]">Rank</div>
                    <div class="text-sm w-[112px]">Player</div>
                    <div class="text-sm w-[105px]">Reward</div>
                    <div class="text-sm w-[64px] text-center">Score</div>
                </div>
            <div class="min-h-0 grow overflow-y-auto rounded-[32px] border-2 border-primary-500 p-4">

                

                <div
                    v-for="(player, index) in showingRank.rankings.slice(1)"
                    :key="player.user_name"
                >
                    <div class="flex items-center justify-between gap-2">
                        <div class="flex items-center gap-1 w-[64px]">
                            <img
                                :src="`https://flagcdn.com/w40/${player.last_country_code.toLowerCase()}.png`"
                                alt=""
                                class="size-6 rounded-full border-2 border-primary-500 object-cover"
                            />
                            <div class="text-sm">
                                {{ 1 + 1 + index }}
                            </div>
                        </div>
                        <div class="text-sm w-[112px]">
                            
                            {{ player.user_name?.slice(0, 9) + (player.user_name?.length > 9 ? ' ..' : '') }}

                        </div>
                        <div class="text-sm w-[105px]">
                            <div v-if="player.is_collected" class="flex items-center gap-1 text-sm">
                                <img
                                    v-if="player.reward_detail.reward_type == 'TiltCoin'"
                                    class="size-5"
                                    src="@/assets/icons/top-nav/diamond.png"
                                    alt=""
                                />
                                <img
                                    v-else-if="player.reward_detail.reward_type == 'Coin'"
                                    class="size-5"
                                    src="@/assets/icons/top-nav/coin.png"
                                    alt=""
                                />
                                {{ player.reward.toLocaleString('en-US') }}
                            </div>
                            <div v-else class="relative inline-block">
                                <button
                                    class="flex items-center gap-2 px-4 py-2 rounded-lg bg-primary-500 hover:bg-primary-500 text-black font-bold shadow-md transition-all cursor-pointer"
                                    @click="claimReward(index, player.reward_detail.id, player.reward_detail.reward, player.reward_detail.reward_type, 'leaderboard')"
                                    :disabled="questStore.leaderBoardOverlayLoaderIndex === index"
                                >
                                    <div  class="flex items-center gap-1 text-sm">
                                        <img
                                            v-if="player.reward_detail.reward_type == 'TiltCoin'"
                                            class="size-5"
                                            src="@/assets/icons/top-nav/diamond.png"
                                            alt=""
                                        />
                                        <img
                                            v-else-if="player.reward_detail.reward_type == 'Coin'"
                                            class="size-5"
                                            src="@/assets/icons/top-nav/coin.png"
                                            alt=""
                                        />
                                        {{ player.reward.toLocaleString('en-US') }}
                                    </div>

                                    
                                </button>
                                <OverlayLoader 
                                    v-if="questStore.leaderBoardOverlayLoaderIndex === index" 
                                    class="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 rounded"
                                />
                            </div>
                        </div>
                        <div class="text-sm w-[64px] text-right">
                            {{ player.total_points.toLocaleString('en-US') }}
                        </div>
                    </div>
                    <div
                        v-show="index !== showingRank.rankings.length - 2"
                        class="my-2 w-full border-b-2 border-primary-500/[.2]"
                    />
                </div>
            </div>
        </div>
    </div>

    <SuccessMessage v-if="questStore.successMessage" />

    <ErrorMessage v-if="questStore.errorMessage" />

</template>
<script setup lang="ts">
import {ref, computed, watch, onMounted, onUnmounted} from 'vue';

import PixelButton from '@/components/universal/PixelButton.vue';
import OverlayLoader from '@/components/universal/OverlayLoader.vue';
import ErrorMessage from '@/components/messages/ErrorMessage.vue';
import SuccessMessage from '@/components/messages/SuccessMessage.vue';

import {useAuthStore} from '@/stores/auth';
import {useQuestStore} from '@/stores/quest';

import {useGameStore} from '@/stores/game';
import {apiGetGameLeaderboardList} from '@/apis';
import {gameRankingSchema} from '@/schemas/rank';
import {z} from 'zod';
import questionImg from '@/assets/imgs/ranks/question.png';
const countdown = ref('');
const timer = ref(0);
const showingGameId = ref('');
const isPrevious = ref(false);

const authStore = useAuthStore();
const gameStore = useGameStore();
const questStore = useQuestStore();

const showingRank = computed(() => {
    if (isPrevious.value) {
        return rankList.value['previous_week'];
    } else {
        return rankList.value['current_week'];
    }
});
const rankList = ref<z.infer<typeof gameRankingSchema>>({
    current_week: {
        week_number: 0,
        year: 1911,
        rankings: [
            {
                rank: 1,
                user_name: '',
                total_points: 0,
                last_country_code: '',
            },
        ],
    },
    previous_week: {
        week_number: 0,
        year: 1911,
        rankings: [{rank: 1, user_name: '', total_points: 0, last_country_code: ''}],
    },
});

function claimReward(index, id, reward, reward_type, reward_from) {
    questStore.claimReward(index, id, reward, reward_type, reward_from);

    if (questStore.claimRewardStatus) {
        if (isPrevious.value) {
            rankList.value['previous_week'].rankings[index+1].is_collected = true;
        } else {
            rankList.value['current_week'].rankings[index+1].is_collected = true;
        }
    }
}

/**
 * @description 獲取遊戲對應的排行榜
 */
async function getRankingListByGameId() {
    try {
        const getRankingResponse = await apiGetGameLeaderboardList(authStore.getRequestConfig);
        rankList.value = gameRankingSchema.parse(getRankingResponse.data);
    } catch (error) {
        console.error(error);
    }
}

// 計算到 UTC+0 周日晚上 23:59:59 的剩餘時間
/**
 *
 */
function updateCountdown() {
    const now = new Date();
    const endOfWeek = new Date();
    const utcDay = endOfWeek.getUTCDay();
    const daysUntilSunday = utcDay === 0 ? 0 : 7 - utcDay;

    endOfWeek.setUTCDate(endOfWeek.getUTCDate() + daysUntilSunday);
    endOfWeek.setUTCHours(23, 59, 59, 999);

    const diff = endOfWeek.getTime() - now.getTime();
    const days = Math.floor(diff / (1000 * 60 * 60 * 24));
    const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));

    countdown.value = `${days} DAYS ${hours} HRS ${minutes} MINS`;
}
// 在組件掛載時啟動計時器
onMounted(() => {
    updateCountdown();
    timer.value = window.setInterval(updateCountdown, 10000);
});

// 在組件卸載時清理計時器
onUnmounted(() => {
    if (timer.value) {
        clearInterval(timer.value);
    }
});
getRankingListByGameId();

watch(showingGameId, () => {
    getRankingListByGameId();
});
</script>
