<template>
    <!-- Modal Background -->
    <div v-if="productStore.showInvoiceModal" class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-80 backdrop-blur-sm px-4">
        <!-- Modal Content -->
        <div class="bg-[#121212] p-6 rounded-xl w-96 border border-primary-500 shadow-[0_0_15px_primary-500]">
            <h2 class="text-xl font-semibold text-primary-500 mb-4">Confirm Invoice</h2>
            <p class="text-gray-300 mb-6">{{ productStore.showInvoiceModalMessage }}</p>
            
            <div class="flex justify-end space-x-4">
                <button
                    class="px-4 py-2 bg-gray-800 text-gray-300 rounded-lg border border-gray-500 hover:bg-gray-700 transition-all"
                    @click="productStore.closeinvoiceConfirmationModal()"
                >Cancel</button>
                
                <button
                    class="px-4 py-2 bg-primary-500 text-black rounded-lg font-bold shadow-lg hover:bg-[#00D48E] transition-all"
                    @click="productStore.sendInvoice()"
                >OK</button>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { useProductStore } from '@/stores/product'; // Adjust path as needed

    const productStore = useProductStore();

</script>
