import {z} from 'zod';

const getQuestListSchema = z.object({
    quests: z.array(
        z.object({
            id: z.string(),
            name: z.string(),
            description: z.string(),
            type: z.string(),
            reward: z.number(),
            reward_type: z.string(),
            progress: z.string().nullable(),
            is_completed: z.boolean(),
            is_collected: z.boolean(),
            reset_at: z.string().nullable(),
        }),
    ),
});
const postDailyCheckingSchema = z.object({
    success: z.boolean(),
    rewards: z.array(
        z.object({
            day_count: z.number(),
            id: z.number(),
            reward: z.number(),
            reward_image: z.string().nullable(),
            reward_name: z.string().nullable(),
            is_completed: z.boolean(),
            is_collected: z.boolean(),
            reward_type: z.string(),
        }),
    ),
});
export {getQuestListSchema, postDailyCheckingSchema};
