<template>
    <div class="flex h-dvh flex-col bg-dark-900">
        <transition name="fade">
            <Loading v-show="delayedLoading" />
        </transition>
        <transition name="fade">
            <div v-if="!isMobileDevice" class="fixed inset-0 z-50 flex w-full h-[100dvh] items-center justify-center bg-[#000000]">
                <div class="mx-4 w-full max-w-md rounded-lg bg-[#000000] p-8 text-center">
                    <h2 class="mb-4 text-4xl font-semibold">Play on Mobile</h2>
                    <img src="@/assets/imgs/qrcode.png"  />
                </div>
            </div>
        </transition>

        <div
            class="safe-area-top"
            :style="{paddingTop: isMobileDevice ? '80px' : '0px'}"
        >
            <TopNav v-if="$route.name !== ROUTER_NAME.RANKS && $route.name !== ROUTER_NAME.FILE" />
        </div>

        <div class="safe-area-content min-h-0 grow overflow-y-auto">
            <router-view />
        </div>

        <div class="safe-area-bottom">
            <BottomNav />
        </div>
    </div>
</template>

<script setup lang="ts">
// Expand Telegram WebApp immediately at app initialization
if (window.Telegram?.WebApp) {
    window.Telegram.WebApp.isVerticalSwipesEnabled = false;
    window.Telegram.WebApp.disableVerticalSwipes = true;
    window.Telegram.WebApp.expand();
    if (window.Telegram.WebApp.platform === 'android' || window.Telegram.WebApp.platform === 'ios') {
        window.Telegram.WebApp.requestFullscreen?.();
    } else {
        console.warn('Fullscreen is not supported in this version of Telegram Web App');
    }
    window.Telegram.WebApp.ready();
}

import {ROUTER_NAME} from './constants/route';
import BottomNav from './components/layouts/BottomNav.vue';
import TopNav from './components/layouts/TopNav.vue';
import Loading from './components/universal/Loading.vue';
import {useLoadingStore} from './stores/loading';
import {computed, ref, watch} from 'vue';

const loadingStore = useLoadingStore();
const delayedLoading = ref(true);
const isMobileDevice = computed(() => {
    return window.Telegram?.WebApp?.platform === 'android' || window.Telegram?.WebApp?.platform === 'ios';
});
watch(
    () => loadingStore.isLoading,
    (isLoading) => {
        if (isLoading) {
            delayedLoading.value = true;
        } else {
            setTimeout(() => {
                delayedLoading.value = false;
            }, 1000);
        }
    },
);
</script>

<style>
body {
    font-family: 'Tektur', sans-serif;
}

/* Safe area classes for Telegram WebApp */
.safe-area-top {
    /* Support both safe area inset types */
    padding-left: var(--tg-safe-area-inset-left, var(--tg-content-safe-area-inset-left, 0px));
    padding-right: var(--tg-safe-area-inset-right, var(--tg-content-safe-area-inset-right, 0px));
}

.safe-area-content {
    /* Support both safe area inset types */
    padding-left: var(--tg-safe-area-inset-left, var(--tg-content-safe-area-inset-left, 0px));
    padding-right: var(--tg-safe-area-inset-right, var(--tg-content-safe-area-inset-right, 0px));
}

.safe-area-bottom {
    /* Support both safe area inset types */
    padding-bottom: var(--tg-safe-area-inset-bottom, var(--tg-content-safe-area-inset-bottom, 0px));
    padding-left: var(--tg-safe-area-inset-left, var(--tg-content-safe-area-inset-left, 0px));
    padding-right: var(--tg-safe-area-inset-right, var(--tg-content-safe-area-inset-right, 0px));
}

div,
span {
    @apply text-neutral-50;
}

body,
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}

.fade_fast-enter-active,
.fade_fast-leave-active {
    transition: opacity 0.25s ease;
}

.fade_fast-enter-from,
.fade_fast-leave-to {
    opacity: 0;
}
</style>
