import axios from 'axios';

const rankingRequest = axios.create({
    baseURL: '/api/webRankings',
});

interface BaseConfig {
    headers: {
        Authorization: string;
    };
}

export const getGameLeaderboardList = (config: BaseConfig) => rankingRequest.get('', config);
