<template>
    <div class="flex items-center justify-between p-4">
        <div class="flex gap-2">
            <router-link :to="{name: ROUTER_NAME.FILE}">
                <img
                    class="size-10 rounded-2xl border-2 border-primary-500 object-cover"
                    :src="userStore.userInfo.userAvatar"
                    alt="userAvatar"
                />
            </router-link>
            <div class="flex flex-col gap-2.5">
                <div class="text-sm">
                    {{ userStore.userInfo.userName }}
                </div>
                <div class="flex gap-2.5">
                    <ProgressBar
                        :max-num="userStore.energy.max"
                        :current-num="userStore.userInfo.energy"
                        active-progress-color="bg-highlight-500"
                        active-progress-accent-color="bg-highlight-700"
                    />
                    <div
                        class="cursor-pointer text-[10px] text-white"
                        @click="toggleFormat"
                    >
                        {{ countdownDisplay }}
                    </div>
                </div>
            </div>
        </div>
        <div class="flex items-center gap-4">
            <DiamondIndicator :remain-diamond="userStore.userInfo.diamond" />
            <CoinIndicator :remain-coin="userStore.userInfo.coin" />
        </div>
    </div>
</template>

<script setup lang="ts">
import {useUserStore} from '@/stores/user';
import {ROUTER_NAME} from '@/constants/route';
import ProgressBar from '../universal/ProgressBar.vue';
import CoinIndicator from '../top-nav/CoinIndicator.vue';
import DiamondIndicator from '../top-nav/DiamondIndicator.vue';
import {ref, computed, onMounted, onUnmounted} from 'vue';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';

dayjs.extend(duration);

const userStore = useUserStore();
const showHourMinute = ref(true);
const currentTime = ref(dayjs());

// Update current time every second
let timer: ReturnType<typeof setInterval>;
onMounted(() => {
    timer = setInterval(() => {
        currentTime.value = dayjs();
    }, 1000);
});

onUnmounted(() => {
    if (timer) clearInterval(timer);
});

const countdownDisplay = computed(() => {
    const nextRefull = dayjs(userStore.userInfo.nextRefull);
    const diff = nextRefull.diff(currentTime.value);

    if (diff <= 0) return '00:00';

    const duration = dayjs.duration(diff);

    if (showHourMinute.value) {
        return `${String(duration.hours()).padStart(2, '0')}:${String(duration.minutes()).padStart(2, '0')}`;
    } else {
        return `${String(duration.minutes()).padStart(2, '0')}:${String(duration.seconds()).padStart(2, '0')}`;
    }
});

const toggleFormat = () => {
    showHourMinute.value = !showHourMinute.value;
};
</script>
