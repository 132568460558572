import {z} from 'zod';
export const userDetailsSchema = z.object({
    user: z.object({
        id: z.string(),
        name: z.string(),
        telegram_id: z.number(),
    }),
    energy: z.object({
        current: z.number(),
        max: z.number().nullable(),
        next_refill: z.string().nullable(),
        last_consumed: z.string().nullable(),
    }),
    currency: z.object({
        soft_currency: z.coerce.number(),
        hard_currency: z.coerce.number(),
    }),
    game_rank: z.array(
        z.object({
            game_id: z.string(),
            rank: z.number(),
            total_points: z.number(),
            updated_at: z.string(),
            game: z.object({
                id: z.string(),
                name: z.string(),
                icon_url: z.string(),
            }),
        }),
    ).nullable(),
    daily_check_in: z
        .object({
            is_completed: z.boolean(),
            rewards: z.object({
                soft_currency: z.number(),
                hard_currency: z.number(),
            }),
            next_reset: z.string(),
        })
        .nullable(),
}); 