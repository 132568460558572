import {z} from 'zod';

const getGameListSchema = z.object({
    games: z.array(
        z.object({
            id: z.string(),
            name: z.string(),
            game_url: z.string(),
            banner_url: z.string(),
            icon_url: z.string(),
            description: z.string(),
            is_active: z.boolean(),
            sort_order: z.number(),
            created_at: z.string().nullable(),
            updated_at: z.string().nullable(),
            points_to_soft_currency_rate: z.number()
                .min(0.001)
                .max(100)
                .refine(val => {
                    // 验证小数点后最多三位
                    const decimalPart = val.toString().split('.')[1];
                    return !decimalPart || decimalPart.length <= 3;
                }, '最多三位小数'),
            energy_cost: z.number(),
        }),
    ),
});

export {getGameListSchema};
