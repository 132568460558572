<template>
    <div class="flex h-[120px] items-center p-4">
        <div class="flex w-full">
            <div class="flex gap-2">
                <div class="relative">
                    <img
                        class="size-20 rounded-[32px] border-2 border-primary-500 object-cover"
                        :src="userStore.userInfo.userAvatar"
                        alt=""
                    />
                </div>
                <div class="flex flex-col justify-center gap-2.5">
                    <div class="text-2xl">
                        {{ userStore.userInfo.userName }}
                    </div>
                    <div class="flex items-center gap-2 text-xs text-highlight-500">
                        ID: {{ truncatedWallet }}
                        <button
                            class="rounded p-1 transition-colors hover:bg-dark-400"
                            @click="copyWallet"
                        >
                            <Copy class="h-4 w-4" />
                        </button>
                    </div>
                </div>
            </div>
            <div class="ml-auto size-10 shrink-0 self-start overflow-hidden rounded-2xl border-2 border-primary-500">
                <img
                    :src="`https://flagcdn.com/w40/${userStore.userInfo.countryCode!.toLowerCase()}.png`"
                    :srcset="`https://flagcdn.com/w80/${userStore.userInfo.countryCode!.toLowerCase()}.png 2x`"
                    :alt="userStore.userInfo.countryCode"
                    class="h-full w-full object-cover"
                />
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import {useUserStore} from '@/stores/user';
import {computed} from 'vue';
import {Copy} from 'lucide-vue-next';

const userStore = useUserStore();

const truncatedWallet = computed(() => {
    const wallet = userStore.userInfo.wallet;
    return `${wallet.slice(0, 6)}...${wallet.slice(-4)}`;
});

const copyWallet = async () => {
    try {
        await navigator.clipboard.writeText(userStore.userInfo.wallet);
    } catch (err) {
        console.error('Failed to copy wallet address:', err);
    }
};
</script>
