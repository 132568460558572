import {z} from 'zod';
export const exchangeTokenSchema = z.object({
    message: z.string(),
    user: z.object({
        id: z.string(),
        name: z.string(),
        email: z.string(),
        email_verified_at: z.string().nullable().optional(),
        telegram_id: z.number(),
        language_code: z.string().optional(),
        created_at: z.string(),
        updated_at: z.string(),
        photo_url: z.string().optional(),
        last_country_code: z.string().optional(),
    }),
    access_token: z.string(),
    token_type: z.string(),
    expires_in: z.number(),
});
