/* eslint-disable @typescript-eslint/naming-convention */
const ROUTER_PATH = {
    PLAY: '/play',
    STORE: '/store',
    RANKS: '/ranks',
    QUEST: '/quest',
    FILE: '/file',
};
const ROUTER_NAME = {
    PLAY: 'play',
    STORE: 'store',
    RANKS: 'ranks',
    QUEST: 'quest',
    FILE: 'file',
};
export {ROUTER_NAME, ROUTER_PATH};
