import {defineStore} from 'pinia';
import {ref} from 'vue';

export const usePreloadAssetsStore = defineStore('preloadAssets', () => {
    const preloadedSmallActiveButtomImage = ref('');
    const preloadedSmallIdleButtomImage = ref('');
    const preloadedMediumActiveButtomImage = ref('');
    const preloadedMediumIdleButtomImage = ref('');
    const preloadedLargeActiveButtomImage = ref('');
    const preloadedLargeIdleButtomImage = ref('');
    const preloadedPlayActiveButtomImage = ref('');
    const preloadedPlayIdleButtomImage = ref('');
    const preloadedLoadingOffImage = ref('');
    const preloadedLoadingOnImage = ref('');
    /**
     * @description 預載入圖片
     * @param {string} url 要載入的圖片網址，如果是本地資源，記得使用new URL來載入
     * @returns {Promise<string>} 載入後的圖片網址
     */
    function preloadImage(url: string): Promise<string> {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.src = url;
            // 當圖片成功加載後觸發
            img.onload = async () => {
                const response = await fetch(url);
                if (!response.ok) {
                    reject(new Error(`Network response was not ok: ${response.statusText}`));
                }

                const blob = await response.blob();

                // 使用 FileReader 將 blob 轉換為 base64 Data URL
                const reader = new FileReader();
                reader.onloadend = () => {
                    const base64Data = reader.result;
                    if (typeof base64Data === 'string') {
                        resolve(base64Data);
                    }
                };
                reader.readAsDataURL(blob);
            };
            img.onerror = () => {
                reject(new Error(`Image load failed: ${url}`));
            };
        });
    }
    /**
     * 將指定網址的影片載入並轉成 Base64
     * @param {string} url - 影片來源連結
     * @returns {Promise<string>} 影片的 Base64 字串（含 data URI）
     */
    async function preloadVideoAsBase64(url: string): Promise<string> {
        // 1. 使用 fetch 抓取影片
        const response = await fetch(url);
        if (!response.ok) {
            throw new Error(`無法載入影片：HTTP status ${response.status}`);
        }

        // 2. 將影片轉成 Blob
        const blob = await response.blob();

        // 3. 讀取 Blob 並轉成 Base64
        return new Promise<string>((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => {
                /**
                 * FileReader 的 result 可能是 string | ArrayBuffer | null，
                 * 但 readAsDataURL() 保證回傳的是 string，因此可安全轉型。
                 */
                if (typeof reader.result === 'string') {
                    resolve(reader.result);
                } else {
                    reject(new Error('讀取失敗，無法解析成字串'));
                }
            };
            reader.onerror = (error) => reject(error);
            reader.readAsDataURL(blob);
        });
    }
    /**
     * @description 載入全部的圖片
     */
    async function preloadAllImage() {
        preloadedSmallActiveButtomImage.value = await preloadImage(
            new URL('@/assets/imgs/button/small-active.png', import.meta.url).href,
        );
        preloadedSmallIdleButtomImage.value = await preloadImage(
            new URL('@/assets/imgs/button/small-idle.png', import.meta.url).href,
        );
        preloadedMediumActiveButtomImage.value = await preloadImage(
            new URL('@/assets/imgs/button/medium-active.png', import.meta.url).href,
        );
        preloadedMediumIdleButtomImage.value = await preloadImage(
            new URL('@/assets/imgs/button/medium-idle.png', import.meta.url).href,
        );
        preloadedLargeActiveButtomImage.value = await preloadImage(
            new URL('@/assets/imgs/button/large-active.png', import.meta.url).href,
        );
        preloadedLargeIdleButtomImage.value = await preloadImage(
            new URL('@/assets/imgs/button/large-idle.png', import.meta.url).href,
        );
        preloadedPlayActiveButtomImage.value = await preloadImage(
            new URL('@/assets/icons/bottom-nav/play-active.png', import.meta.url).href,
        );
        preloadedPlayIdleButtomImage.value = await preloadImage(
            new URL('@/assets/icons/bottom-nav/play-idle.png', import.meta.url).href,
        );
    }
    return {
        preloadedSmallActiveButtomImage,
        preloadedSmallIdleButtomImage,
        preloadedMediumActiveButtomImage,
        preloadedMediumIdleButtomImage,
        preloadedLargeActiveButtomImage,
        preloadedLargeIdleButtomImage,
        preloadedPlayActiveButtomImage,
        preloadedPlayIdleButtomImage,
        preloadedLoadingOffImage,
        preloadedLoadingOnImage,
        preloadAllImage,
        preloadImage,
        preloadVideoAsBase64,
    };
});
