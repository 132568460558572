import axios from 'axios';

interface BaseConfig {
    headers: {
        Authorization: string;
    };
}


const productRequest = axios.create({
    baseURL: '/api/product',
});

export const getProductList = (type: number) => productRequest.get('', {
    params: { type }
});



const bannerRequest = axios.create({
    baseURL: '/api/banner',
});

export const getBannerList = () => bannerRequest.get('');


const verifyInvoiceRequest = axios.create({
    baseURL: '/api/verify-invoice',
});
export const postVerifyInvoice = (config: BaseConfig, product_id: number) => verifyInvoiceRequest.post('', {product_id}, config);



const sendInvoiceRequest = axios.create({
    baseURL: '/api/send-invoice',
});
export const postSendInvoice = (config: BaseConfig, product_id: number) => sendInvoiceRequest.post('', {product_id}, config);
