import axios from 'axios';

const flagRequest = axios.create({
    baseURL: '/api/flag',
});

interface BaseConfig {
    headers: {
        Authorization: string;
    };
}

export const getUserFlag = (config: BaseConfig) => flagRequest.get('', config);
