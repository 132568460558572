import {createRouter, createWebHistory} from 'vue-router';
import Play from '@/views/Play.vue';
import Store from '@/views/Store.vue';
import Ranks from '@/views/Ranks.vue';
import Quest from '@/views/Quest.vue';
import File from '@/views/Profile.vue';
import {ROUTER_NAME, ROUTER_PATH} from '@/constants/route';
import {usePreloadAssetsStore} from '@/stores/preloadAssets';
import {useAuthStore} from '@/stores/auth';
import {useLoadingStore} from '@/stores/loading';
import {useUserStore} from '@/stores/user';
const routes = [
    {
        path: '/',
        redirect: ROUTER_PATH.PLAY,
    },
    {
        path: ROUTER_PATH.PLAY,
        name: ROUTER_NAME.PLAY,
        component: Play,
    },
    {
        path: ROUTER_PATH.STORE,
        name: ROUTER_NAME.STORE,
        component: Store,
    },
    {
        path: ROUTER_PATH.RANKS,
        name: ROUTER_NAME.RANKS,
        component: Ranks,
    },
    {
        path: ROUTER_PATH.QUEST,
        name: ROUTER_NAME.QUEST,
        component: Quest,
    },
    {
        path: ROUTER_PATH.FILE,
        name: ROUTER_NAME.FILE,
        component: File,
    },
];

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes,
});

router.beforeEach(async (to) => {
    const preloadAssetsStore = usePreloadAssetsStore();
    const authStore = useAuthStore();
    const loadingStore = useLoadingStore();
    const userStore = useUserStore();
    
    if (!authStore.isInitialized) {
        try {
            await preloadAssetsStore.preloadAllImage();
            await authStore.initialize();
            await userStore.fetchUserDetails();
            loadingStore.stopLoading();
        } catch (error) {
            console.error('Initialization error:', error);
        }
    } else if (to.name === ROUTER_NAME.STORE) {
        // 進入商店時更新用戶資料
        try {
            await userStore.fetchUserDetails();
        } catch (error) {
            console.error('Failed to update user details:', error);
        }
    }
});

export default router;
