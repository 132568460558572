import {defineStore} from 'pinia';
import type {QuestList} from '@/types/quest';
import {getQuestListSchema, postDailyCheckingSchema} from '@/schemas/quest';
import {userDetailsSchema} from '@/schemas/user';
import {apiGetQuestList, apiClaimReward, apiPostDailyCheckIn, apiGetUserMe } from '@/apis';
import {ref} from 'vue';
import {useAuthStore} from './auth';
import {useUserStore} from './user';


export const useQuestStore = defineStore('quest', () => {
    const questList = ref<QuestList>([]);
    const questOverlayLoaderIndex = ref<number | null>(null);
    const dailyCheckinOverlayLoaderIndex = ref<number | null>(null);
    const leaderBoardOverlayLoaderIndex = ref<number | null>(null);

    const rewardsList = ref<any>([]);
    const isOpen = ref<boolean>(false);


    const authStore = useAuthStore();
    const userStore = useUserStore();

    const successMessage = ref<boolean>(false);
    const errorMessage = ref<boolean>(false);
    const claimRewardStatus = ref<boolean>(false);


    const fetchQuest = async () => {
        try {
            const getQuestListResponse = await apiGetQuestList(authStore.getRequestConfig);
            questList.value = getQuestListSchema.parse(getQuestListResponse.data).quests;
        } catch (error) {
            console.error('Error fetching quest:', error);
        }
    };

    const handleDailyCheckIn = async () => {
        try {
            const dailyCheckInResponse = await apiPostDailyCheckIn(authStore.getRequestConfig);
            const dailyCheckInData = postDailyCheckingSchema.parse(dailyCheckInResponse.data);
    
            if (dailyCheckInData.success) {

                rewardsList.value = dailyCheckInData.rewards;
                isOpen.value = true;

            }
        } catch (error) {
            console.error(error);
            
        }
    }

    const claimReward = async (
        index:number,
        reward_id:string | number,
        reward_amount: number,
        reward_type:string,
        reward_from: string
    ) => {
        try {

            if (reward_from == "quest") {
                questOverlayLoaderIndex.value = index;
            } else if (reward_from == "leaderboard") {
                leaderBoardOverlayLoaderIndex.value = index;
            } else {
                dailyCheckinOverlayLoaderIndex.value = index;
            }

            const claimRewardResponse = await apiClaimReward(
                authStore.getRequestConfig,
                reward_id,
                reward_amount,
                reward_type,
                reward_from
            );

            if (claimRewardResponse.data.success) {

                const getUserResponse = await apiGetUserMe(authStore.getRequestConfig);
                const getUserData = userDetailsSchema.parse(getUserResponse.data);

                userStore.updateCurrency(getUserData.currency);
                if (reward_from == "quest") {
                    questList.value[index].is_collected = true;
                    questOverlayLoaderIndex.value = null;
                } else if (reward_from == "leaderboard") {
                    leaderBoardOverlayLoaderIndex.value = null;
                } else {
                    rewardsList.value[index].is_collected = true;
                    dailyCheckinOverlayLoaderIndex.value = null;
                }
                successMessage.value = true;
                claimRewardStatus.value = true;

                setTimeout(() => {
                    successMessage.value = false;
                }, 3000);

            } else {
                errorMessage.value= true;
                claimRewardStatus.value = false;

                setTimeout(() => {
                    errorMessage.value = false;
                }, 3000);
            }
        } catch (error) {
            if (reward_from == "quest") {
                questOverlayLoaderIndex.value = null;
            } else if (reward_from == "leaderboard") {
                leaderBoardOverlayLoaderIndex.value = null;
            } else {
                dailyCheckinOverlayLoaderIndex.value = null;
            }

            errorMessage.value= true;
            claimRewardStatus.value = false;

            setTimeout(() => {
                errorMessage.value = false;
            }, 3000);
            console.error('Error fetching quest:', error);
        }
    };

    const closeModal = () => {
        isOpen.value = false;
    }

    return {
        questList,
        rewardsList,
        isOpen,
        questOverlayLoaderIndex,
        dailyCheckinOverlayLoaderIndex,
        leaderBoardOverlayLoaderIndex,
        successMessage,
        errorMessage,
        claimRewardStatus,
        fetchQuest,
        claimReward,
        handleDailyCheckIn,
        closeModal,
    };
});
