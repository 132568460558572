import axios from 'axios';

const tokenRequest = axios.create({
    baseURL: '/api/token',
});

interface ExchangeToken {
    initData: string;
}

export const postExchangeToken = (data: ExchangeToken) => tokenRequest.post('', data);
